
import {Component, Vue, Watch} from "vue-property-decorator"
import DataItem from "../../components/DataItem.vue"
import WorkDaySetup from "./WorkDaySetup.vue"
import Filters from "./Filters/Filters.vue"
import {workScheduleService} from "../../services/work-schedule-service"
import {staffService} from "../../services/staff-service"
import {utils} from "../../shared/utils"
import moment from "moment"
import {userService} from "@/services/user-service"
import router from "@/router"

@Component({
  components: {DataItem, WorkDaySetup, Filters},
})
export default class WorkScheduleView extends Vue {
  data: any = {
    staffId: null,
    staffs: [],
    profile: {},
    days: [],
    startDate: null,
    rangeInDays: 10,
    offsetDaysView: -1,
    from: "",
    to: "",
    selectedDeptId: null,
    selectedSiteID: null,
  }

  pageSettings: any = {}

  workScheduleByStaff: any = {}

  created() {
    this.init()
  }
  refresh() {
    this.setupDays()
  }
  viewStaff(staffId) {
    router.push({name: "StaffView", params: {id: staffId}})
  }
  goPrev() {
    this.data.offsetDaysView = this.data.offsetDaysView - this.data.rangeInDays
    this.setupDays()
  }
  goNext() {
    this.data.offsetDaysView = this.data.offsetDaysView + this.data.rangeInDays
    this.setupDays()
  }
  async init() {
    this.data.profile = this.$store.getters.profile
    this.data.selectedDeptId = this.data.profile.department ? this.data.profile.department._id : null
    this.data.selectedSiteID = this.data.profile.site ? this.data.profile.site.siteID : null
    this.loadStaffForSiteAndDepartment()
  }

  async loadStaffForSiteAndDepartment() {
    let deptId = this.data.selectedDeptId
    let siteID = this.data.selectedSiteID
    if (deptId && siteID) {
      let result = await staffService.loadStaffForSiteAndDepartment(siteID, deptId)
      if (!result || result.error) {
        utils.showSnackbarError("Error loading staff")
        return
      }
      this.data.staffs = result.data

      this.setupDays()
    } else {
      if (userService.hasRole("ADMIN") || userService.hasRole("MANAGER")) {
        console.log("ADMIN")
      } else {
        utils.showSnackbarWarning("Access denied")
      }
    }
  }

  async setupDays() {
    let deptId = this.data.selectedDeptId
    let siteID = this.data.selectedSiteID
    if (!deptId || !siteID) {
      utils.showSnackbarInfo("Select Site/Department to view Work Schedule")
      return
    }
    this.data.days = []
    let days = this.data.rangeInDays
    let minus = this.data.offsetDaysView
    let from = moment().add(minus, "days")
    let fromString = moment()
      .add(minus, "days")
      .format("YYYY-MM-DD")
    let toString = moment()
      .add(days + minus, "days")
      .format("YYYY-MM-DD")

    this.data.from = moment(fromString).format("DD-MMM")
    this.data.to = moment(toString).format("DD-MMM")

    let result = await workScheduleService.loadStaffWorkScheduleForDateRange(siteID, deptId, fromString, toString)

    if (!result || result.error) {
      console.log("Error while loading scheduling data")
      utils.showSnackbarError("Error loading schedule information")
      return
    }
    let scheduleData = result.data
    this.workScheduleByStaff = scheduleData.reduce((acc, curr) => {
      acc[curr._id.staffId] = {staffId: curr._id.staffId, name: curr.name, dates: curr.val}
      if (curr.val) {
        curr.val.forEach((dv) => {
          let dlabel = moment(dv.d).format("DD-MMM")
          if (!acc[dlabel]) {
            acc[dlabel] = []
          }
          acc[dlabel].push(dv.v)
        })
      }
      return acc
    }, {})
    console.log("scheduleData", scheduleData)

    for (let index = 0; index <= days; index++) {
      var isWeekend = from.toDate().getDay() % 6 == 0
      this.data.days.push({
        date: from.toDate(),
        label: from.format("DD-MMM"),
        labelDay: from.format("ddd"),
        today: index + minus == 0,
        wkend: isWeekend,
      })
      from.add(1, "days")
    }
  }

  getValue(staffId, dateLabel) {
    let staff = this.workScheduleByStaff[staffId]
    if (staff) {
      let date = staff.dates.find((i) => {
        return moment(i.d).format("DD-MMM") == dateLabel
      })
      if (date) {
        return date.v
      }
      return ""
    }
    return ""
  }

  summaryByDate(dateLabel) {
    let stats = this.workScheduleByStaff[dateLabel]
    let totalPerc = 0
    if (stats) {
      let total = stats.length
      let totalWFH = stats.filter((i) => {
        return i == "WFH"
      }).length
      totalPerc = Math.floor((totalWFH / total) * 100)
    }
    return totalPerc
  }

  onChangedSchedule(event) {
    console.log("onChangedSchedule", event)
    if (this.workScheduleByStaff[event.staffId]) {
      let index = this.workScheduleByStaff[event.staffId].dates.findIndex((i) => {
        return moment(i.d).format("DD-MMM") == moment(event.date).format("DD-MMM")
      })
      console.log("found Existing", index)
      if (index >= 0) {
        console.log("Updating existing date value for staff")
        let newV = {d: moment(event.date).utc(), v: event.value}
        this.workScheduleByStaff[event.staffId].dates.splice(index, 1, newV)
      }
    }
  }
  filterUpdated(data) {
    console.log("filterUpdated", data)
    let obj = {}
    if (data.key == "range") {
      obj = data.value
    } else {
      obj[data.key] = data.value
    }
    if (data.key == "department") {
      this.data.selectedDeptId = data.value
      this.loadStaffForSiteAndDepartment()
    }
    if (data.key == "siteIDs") {
      this.data.selectedSiteID = data.value
      this.loadStaffForSiteAndDepartment()
    }
  }
  backButtonClicked() {
    this.$router.back()
  }
}
