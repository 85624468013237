
import {Component, Vue} from "vue-property-decorator"
import EventBus from "../eventBus"

@Component({})
export default class SimpleLayout extends Vue {
  data: any = {
    snackbar: {
      visible: false,
      text: "",
      buttonColor: "white",
    },
  }

  constructor() {
    super()
  }

  created() {
    EventBus.$on("display-snackbar", this.displaySnackbar)
  }

  beforeDestroy() {
    EventBus.$off("display-snackbar", this.displaySnackbar)
  }

  displaySnackbar(payload: any) {
    this.data.snackbar.visible = false // first close any open snackbar
    this.data.snackbar.text = payload.text
    this.data.snackbar.visible = true

    switch (payload.type) {
      case "success": {
        this.data.snackbar.color = "success"
        this.data.snackbar.buttonColor = "white"
        break
      }
      case "error": {
        this.data.snackbar.color = "error"
        this.data.snackbar.buttonColor = "white"
        break
      }
      case "warning": {
        this.data.snackbar.color = "warning"
        //this.data.snackbar.buttonColor = "white";
        break
      }
      case "info": {
        this.data.snackbar.color = "info"
        //this.data.snackbar.buttonColor = "white";
        break
      }
      default: {
        this.data.snackbar.color = "primary"
        this.data.snackbar.buttonColor = "white"
        break
      }
    }
  }
}
