import store from "@/store"
import {baseService} from "./base-service"
import {cacheSrv} from "./cache"
class PreferenceService {
  URL_PREFIX = "preferences/"

  async saveEmailSignature(payload: any) {
    return await baseService.post(`${this.URL_PREFIX}email-signature`, payload)
  }

  async getPreference() {
    return await baseService.get(`${this.URL_PREFIX}`)
  }

  async loadStaffProfile(staffId) {
    let result = await baseService.get(`staff/${staffId}/core-profile`)
    if (result && result.data) {
      return result.data
    }
    return null
  }
}
export const preferenceService = new PreferenceService()
