import {BaseDomainService} from "./base-domain-service"

class UserAccessService extends BaseDomainService {
  URL_PREFIX = "user-access/"
  CACHE_KEY = "user-access-list"

  async updateUserAccess(userId: string, payload: any) {
    return await this.post(`${this.URL_PREFIX}${userId}/user-account`, payload)
  }
  async saveUserAccess(userId: string, payload: any) {
    return await this.post(`${this.URL_PREFIX}${userId}/register-user-account`, payload)
  }
  async resetAttempts(userId: string) {
    return await this.post(`${this.URL_PREFIX}${userId}/reset-attempts`, {})
  }
  
}

export const userAccessService = new UserAccessService()
