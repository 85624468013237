
import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import {staffService} from "../../services/staff-service"
import BaseTimer from "../../components/BaseTimer.vue"
import DataItem from "../../components/DataItem.vue"

@Component({
  components: {DataItem, BaseTimer},
})
export default class Header extends Vue {
  @Prop({required: true})
  item

  data: any = {
    item: {},
  }

  hods: any = []

  created() {
    Vue.set(this.data, "item", this.item)
    this.loadHODs()
  }
  async loadHODs() {
    let item = this.data.item
    console.log("loadHODs started", item.site, item.department)
    if (item.site && item.site.siteID && item.department && item.department._id) {
      let hods = await staffService.loadHODsForSiteAndDepartment(item.site.siteID, item.department._id)
      if (hods && !hods.error) {
        this.hods = hods.data
      }
    }
  }
}
