import store from "./../store"
import axios from "axios"
import router from "./../router"
//import eventBus from "./../eventBus"
import {cacheSrv} from "./cache"
import {utils} from "@/shared/utils"
import {socketService} from "./socket-service"
import {preferenceService} from "./preferences-service"

class UserService {
  ssoApiUrl = process.env.VUE_APP_HR_SSO_API // loaded from .env.local (or .env)  // DO THIS
  CACHE_KEY_TOKEN = "bpa_token"
  CACHE_KEY_PROFILE = "bpa_user"

  profile: any = {}
  token = null
  policyToken = null

  constructor() {}

  async login(payload) {
    return await axios.post(`${this.ssoApiUrl}/api/admin/login`, payload)
  }

  async loadProfile() {
    return await this.get(`${this.ssoApiUrl}/api/admin/profile`)
  }

  async initUser(force = false) {
    this.token = cacheSrv.cached(this.CACHE_KEY_TOKEN)
    if (!this.token) {
      //this.redirectToLogin()
      return
    } else {
      store.commit(`updateToken`, this.token)
      let profile = cacheSrv.cached(this.CACHE_KEY_PROFILE)
      //socketService.initSocket(this.token)
      if (force || !profile || !profile.name) {
        //console.log("No profile cached. Load from server")
        await this.loadUserProfile()
      } else {
        this.profile = profile
        this.policyToken = profile.policyToken
        store.commit(`updateProfile`, this.profile)
      }
    }
  }

  async loadUserProfile() {
    if (this.token) {
      let data
      try {
        data = await this.get(`${this.ssoApiUrl}/api/admin/profile`)
      } catch (e) {
        console.log("Error while authenticating", this.ssoApiUrl)
        return
      }
      if (data) {
        if (data["error"]) {
          console.log("Error in validating number")
          return
        }
        if (data) {
          let staffData: any = await preferenceService.loadStaffProfile(data._id)
          if (staffData) {
            data = Object.assign({}, data, staffData)
            this.policyToken = data.policyToken
          }
          this.storeProfileLocally(data)
        } else {
          console.log("no token")
        }
      } else {
        console.log("Error in validating username. No data")
      }
    }
  }

  storeProfileLocally(profile: any) {
    this.profile = profile
    cacheSrv.cacheEx(this.CACHE_KEY_PROFILE, this.profile, 60 * 10)
    store.commit(`updateProfile`, this.profile)
  }

  async saveProfile(payload: any) {
    return await this.post(`${this.ssoApiUrl}/api/admin/profile`, payload)
  }

  getToken() {
    return this.token
  }

  getPolicyToken() {
    return this.policyToken
  }

  getProfile() {
    return this.profile
  }

  hasRole(role: string) {
    return this.profile.roles.includes(role)
  }

  hasAccessToSite(siteID: string) {
    if (this.profile.roles.includes("ADMIN")) {
      //Admin  can see all
      return true
    }
    if (this.profile.sites) {
      let sites = this.profile.sites.map((i) => i.siteID)
      console.log("this.profile.sites", this.profile, sites, siteID)
      return sites.includes(siteID)
    }
    return false
  }

  async saveNewPassword(payload) {
    if (this.token) {
      let result
      var config = {
        headers: {Authorization: "Bearer " + this.token},
      }

      try {
        result = await axios.post(`${this.ssoApiUrl}/api/admin/update-password`, payload, config)
      } catch (e) {
        console.log("Error while saveNewPassword", this.ssoApiUrl, e)
        return false
      }
      if (result.data) {
        let data = result.data
        if (data["error"]) {
          console.log("Error in saveNewPassword")
          return false
        }
        if (data) {
          return true
        } else {
          console.log("no data")
          return false
        }
      } else {
        console.log("Error in saveNewPassword. No data")
        return false
      }
    }
  }

  async post(url: string, payload: any) {
    try {
      let result = await axios.post(url, payload, this.loadHeaders())
      return result.data
    } catch (error) {
      if (error instanceof Error) {
        console.error(error)
        if (error && error.message && error.message.indexOf("403") >= 0) {
          utils.showSnackbar("Access denied. Please login with correct credentials to access the system")

          //eventBus.$emit("display-snackbar", {type: "error", text: "Access denied. Please login with correct credentials to access the system"})
          this.redirectToLogin()
        }
      }
      return null
    }
  }

  async get(url: string, payload: any = {}, pagination: any = {}) {
    try {
      let newpay = Object.assign({}, payload, pagination)
      let options = Object.assign({}, {params: newpay}, this.loadHeaders())
      let result = await axios.get(url, options)
      return result.data
    } catch (error) {
      if (error instanceof Error) {
        console.error(error)
        if (error && error.message && error.message.indexOf("403") >= 0) {
          utils.showSnackbar("Access denied. Please login with correct credentials to access the system")
          //eventBus.$emit("display-snackbar", {type: "error", text: "Access denied. Please login with correct credentials to access the system"})
          this.redirectToLogin()
        }
      }
      return null
    }
  }

  async put(url: string, payload: any) {
    try {
      let result = await axios.put(url, payload, this.loadHeaders())
      return result.data
    } catch (error) {
      if (error instanceof Error) {
        console.error(error)
        if (error && error.message && error.message.indexOf("403") >= 0) {
          utils.showSnackbar("Access denied. Please login with correct credentials to access the system")
          //eventBus.$emit("display-snackbar", {type: "error", text: "Access denied. Please login with correct credentials to access the system"})
          this.redirectToLogin()
        }
      }
      return null
    }
  }

  loadHeaders() {
    let headers: any = {authorization: "Bearer " + this.getToken()}
    let policyToken = this.getPolicyToken()
    if (policyToken) {
      headers.policy = policyToken
    }
    return {headers: headers}
  }

  redirectToLogin() {
    router.push("login")
  }
}

export const userService = new UserService()
