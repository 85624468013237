
import {Component, Vue} from "vue-property-decorator"
import router from "./../router"
import eventBus from "./../eventBus"
import Search from "./Search.vue"
import moment from "moment"
import store from "./../store"
import LeftMenuItems from "./LeftMenuItems.vue"
import {userService} from "../services/user-service"

@Component({
  components: {Search, LeftMenuItems},
})
export default class AppLayout extends Vue {
  mini = true
  mainMenu = null // indicates whether the LHS menu should be
  miniMenu = null // indicates whether the LHS menu should be
  rightMenu = false // indicates whether the LHS menu should be
  items = []

  headings: any = []
  activeMini = 0
  mainLinks: any = []

  data: any = {
    snackbar: {
      visible: false,
      text: "",
      buttonColor: "white",
    },
    profile: {},
  }

  clock: any = {
    time: "",
    date: "",
  }

  timerID
  week = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"]

  updateTime() {
    var cd = new Date()
    this.clock.time = this.zeroPadding(cd.getHours(), 2) + ":" + this.zeroPadding(cd.getMinutes(), 2) + ":" + this.zeroPadding(cd.getSeconds(), 2)
    this.clock.date = this.zeroPadding(cd.getFullYear(), 4) + "-" + this.zeroPadding(cd.getMonth() + 1, 2) + "-" + this.zeroPadding(cd.getDate(), 2)
  }

  zeroPadding(num, digit) {
    var zero = ""
    for (var i = 0; i < digit; i++) {
      zero += "0"
    }
    return (zero + num).slice(-digit)
  }

  created() {
    this.renderMenuItems()
    eventBus.$on("display-snackbar", this.displaySnackbar)
    this.data.profile = store.getters.profile
    this.timerID = setInterval(this.updateTime, 1000)
    this.updateTime()
    this.presetLastLink()
  }

  renderMenuItems() {
    let settings = [
      {icon: "mdi-cog", text: "My Settings", route: {name: "settings"}},
      {icon: "mdi-logout", text: "Logout", route: {name: "logout"}},
    ]
    let users = [
      {icon: "mdi-account", text: "Staff", route: "/staff"},
      {role: "admin", icon: "mdi-account-tie", text: "HR Managers", route: "/user-management/manager"},
      {role: "admin", icon: "mdi-account-tie", text: "Admin Users", route: "/user-management/admin"},
    ]
    if (!userService.hasRole("ADMIN")) {
      settings = [
        {icon: "mdi-cog", text: "My Settings", route: {name: "settings"}},
        {icon: "mdi-logout", text: "Logout", route: {name: "logout"}},
      ]
    }
    if (!userService.hasRole("SUPER")) {
      users = [
        {icon: "mdi-account", text: "Staff", route: "/staff"},
        {role: "admin", icon: "mdi-account-tie", text: "HR Managers", route: "/user-management/manager"},
      ]
    }

    let items: any = [
      {
        text: "Dashboard",
        icon: "mdi-shape",
        items: [{icon: "mdi-view-dashboard", text: "Summary", route: "/"}],
      },
      {
        text: "Management",
        icon: "mdi-tray-full",
        items: [
          {
            icon: "mdi-",
            text: "Test Submissions",
            route: "/covid-test-results",
          },
          {
            icon: "mdi-",
            text: "Tests by Staff",
            route: "/covid-test-by-staff",
          },
          {
            icon: "mdi-",
            text: "Work Schedule",
            route: "/work-schedule",
          },
        ],
      },
      {
        text: "Reports",
        icon: "mdi-map",
        items: [
          {
            icon: "mdi-",
            text: "Tests by Site",
            route: "/reports/submissions-by-site",
          },
          {
            icon: "mdi-",
            text: "Tests by Dept",
            route: "/reports/submissions-by-department",
          },
          {
            icon: "mdi-",
            text: "Tests by Reason",
            route: "/reports/submissions-by-reason",
          },
          {
            icon: "mdi-",
            text: "Tests by Results",
            route: "/reports/submissions-by-result",
          },
          {
            icon: "mdi-",
            text: "Work Schedule",
            route: "/reports/work-schedule",
          },
        ],
      },
      {
        role: "admin",
        text: "Configuration",
        icon: "mdi-cog",
        items: [
          {icon: "mdi-", text: "Malls/Sites", route: "/sites"},
          {icon: "mdi-", text: "Departments", route: "/departments"},
          {icon: "mdi-", text: "COV-Test Reasons", route: "/covid-test-reason"},
          {icon: "mdi-", text: "Close Contact Cat.", route: "/close-contact-category"},
          {icon: "mdi-", text: "Meet Reasons", route: "/staff-close-contact-reason"},
        ],
      },

      {
        text: "Users",
        icon: "mdi-account-group",
        items: users,
      },

      {
        text: "Settings",
        icon: "mdi-account",
        items: settings,
      },
    ]

    let itms = items.filter((i) => {
      if (i.role) {
        if (!userService.hasRole(i.role.toUpperCase())) {
          return false
        }
      }
      return true
    })
    itms.forEach((i) => {
      let subs = []
      if (i.items) {
        subs = i.items.filter((s) => {
          if (s.role) {
            if (!userService.hasRole(s.role.toUpperCase())) {
              return false
            }
          }
          return true
        })
      }
      i.items = subs
    })
    Vue.set(this, "items", itms)

    this.headings = this.items.filter((i: any) => {
      return i.items
    })
  }
  presetLastLink() {
    let lastLink = store.getters.lastLink
    if (!lastLink) {
      this.filterLinks(this.headings[0], 0, false)
    } else {
      if (this.headings[lastLink]) {
        this.mainLinks = this.headings[lastLink].items
        this.activeMini = lastLink
      }
    }
  }
  filterLinks(link, index, gotoLink = true) {
    this.mainLinks = link.items
    store.commit(`updateLastLink`, index)
    if (this.mainLinks.length > 0 && gotoLink) {
      this.goto(this.mainLinks[0])
    }
    this.activeMini = index
  }
  goto(link) {
    this.$router.push(link.route).catch(() => {
      console.log("no")
    })
  }

  beforeDestroy() {
    clearInterval(this.timerID)
    eventBus.$off("display-snackbar", this.displaySnackbar)
  }

  accountSettings() {
    router.push({name: "settings"}).catch((err) => {
      console.log("error routing", err)
    })
  }

  displaySnackbar(payload: any) {
    this.data.snackbar.visible = false // first close any open snackbar
    this.data.snackbar.text = payload.text
    this.data.snackbar.visible = true

    switch (payload.type) {
      case "success": {
        this.data.snackbar.color = "success"
        //this.data.snackbar.buttonColor = "white";
        break
      }
      case "error": {
        this.data.snackbar.color = "error"
        //this.data.snackbar.buttonColor = "white";
        break
      }
      case "warning": {
        this.data.snackbar.color = "warning"
        //this.data.snackbar.buttonColor = "white";
        break
      }
      case "info": {
        this.data.snackbar.color = "info"
        //this.data.snackbar.buttonColor = "white";
        break
      }
      default: {
        this.data.snackbar.color = "primary"
        //this.data.snackbar.buttonColor = "white";
        break
      }
    }
  }
}
