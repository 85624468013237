
import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import {dashboardService} from "../../../services/dashboard-service"

@Component({
  components: {},
})
export default class SiteTotals extends Vue {
  @Prop({required: true})
  items

  data: any = {
    options: {
      theme: {
        palette: "palette3",
      },
      labels: [],
      grid: {
        show: true,
      },
      chart: {
        width: "100%",
        dropShadow: {
          enabled: true,
          color: "#999",
          top: 2,
          left: 2,
          blur: 5,
          opacity: 0.2,
        },
        id: "tickets-by-site",
        events: {
          click: (event, chartContext, config) => {
            console.log("event, chartContext, config", event, chartContext, config)
            // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
            this.goto(config)
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      xaxis: {
        categories: [],
      },
    },
    series: [
      {
        name: "series-1",
        data: [],
      },
    ],
  }

  created() {
    this.loadChart(this.items)
  }
  @Watch("items")
  async loadChart(items) {
    console.log("carthing..........items", items)
    let total = 0
    let ser1 = items.map((i) => {
      total = total + i.total
      return i.total
    })
    let labels = items.map((i) => {
      return i.label + " - " + i.total + " (" + (100 * (i.total / total)).toFixed(1) + "%)"
    })

    Vue.delete(this.data, "series")
    Vue.delete(this.data.options, "labels")
    this.$nextTick(() => {
      Vue.set(this.data.options, "labels", labels)
      Vue.set(this.data, "series", ser1)
    })
  }
  goto(config) {
    // if (config && config.dataPointIndex >= 0) {
    //   let selectedValue = this.data.options.xaxis.categories[config.dataPointIndex]
    //   console.log("selectedValue", selectedValue)
    //   this.$router.push({name: "Tickets", query: {site: selectedValue}})
    // }
  }
}
