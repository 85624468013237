class Cache {
  prefix = "admin-"
  localStore: any

  constructor() {
    if (window.localStorage) {
      try {
        this.localStore = window.localStorage
        setInterval(() => {
          console.log("clean local storage")
          Object.keys(this.localStore).forEach((key) => {
            let ex = this.localStore.getItem(this.prefix + key + "_ex")
            if (ex) {
              var start = new Date().getTime()
              if (start > ex) {
                this.localStore.removeItem(this.prefix + key)
                this.localStore.removeItem(this.prefix + key + "_ex")
                console.log("expired cache key", this.prefix + key)
              }
            }
          })
        }, 60000)
      } catch (e) {
        console.log("Local storage not supported on this browser. May need to use cookies")
      }
    }
  }

  cacheEx(key: string, value: any, expireInSeconds: number) {
    if (this.localStore) {
      let toStr = JSON.stringify(value)
      this.localStore.setItem(this.prefix + key, toStr)

      var start = new Date().getTime()
      let ttl = start + expireInSeconds * 1000
      this.localStore.setItem(this.prefix + key + "_ex", ttl)
    } else {
      console.log("No local storage. cant store value")
    }
  }
  cache(key: string, value: any) {
    if (this.localStore) {
      let toStr = JSON.stringify(value)
      this.localStore.setItem(this.prefix + key, toStr)
    } else {
      console.log("No local storage. cant store value")
    }
  }

  remove(key: string) {
    if (this.localStore) {
      this.localStore.removeItem(this.prefix + key)
      this.localStore.removeItem(this.prefix + key + "_ex")
    } else {
      console.log("No local storage. cant remove value")
    }
  }

  cached(key: string) {
    if (this.localStore) {
      let fromStr = this.localStore.getItem(this.prefix + key)
      if (fromStr) {
        let ex = this.localStore.getItem(this.prefix + key + "_ex")
        if (ex) {
          var start = new Date().getTime()
          if (start > ex) {
            this.localStore.removeItem(this.prefix + key)
            this.localStore.removeItem(this.prefix + key + "_ex")
            console.log("expired cache key", this.prefix + key)
            return null
          }
        }
        return JSON.parse(fromStr)
      }
      return null
    } else {
      return null
    }
  }
}

export const cacheSrv = new Cache()
