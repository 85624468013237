
import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import {debounce} from "./../shared/debounce"
import {userService} from "../services/user-service"

@Component({
  components: {},
})
export default class ActivitySelect extends Vue {
  @Prop()
  item

  @Prop()
  items

  @Prop()
  disabled

  data: any = {
    items: [],
    isLoading: false,
    model: null,
    search: null,
  }
  created() {
    this.loadItems()
    this.data.model = this.item
  }
  @Watch("item")
  changedItem() {
    if (this.item) {
      this.data.model = this.item
    }
  }
  onChange(val) {
    this.$emit("onChange", {key: "roles", value: this.data.model})
  }

  @Watch("data.search")
  find(val) {
    if (val == null) return
    debounce.delay(() => {
      this.loadItems()
    }, 450)
  }
  async loadItems() {
    if (this.items) {
      this.data.items = this.items
    } else {
      if (userService.hasRole("SUPER")) {
        this.data.items = ["CS-HQ", "ADMIN", "SUPER"]
      } else if (userService.hasRole("ADMIN")) {
        this.data.items = ["CS-HQ"]
      } else {
        this.data.items = []
      }
    }
  }
}
