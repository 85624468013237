class Debounce {
  timeout = null;

  constructor() {
    //console.log("Debounce loaded");
  }

  delay(func, wait, immediate = false, ...restArgs) {
    //console.log("Deboujce delay called");
    //const context = this;
    const args = restArgs;

    let later = () => {
      this.timeout = null;
      if (!immediate) func.apply(this, args);
    };

    let callNow = immediate && !this.timeout;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(later, wait);

    if (callNow) {
      func.apply(this, args);
    }
  }
}

export const debounce = new Debounce();
