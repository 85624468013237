
import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import {Editor, EditorContent} from "@tiptap/vue-2"
import StarterKit from "@tiptap/starter-kit"

@Component({
  components: {EditorContent},
})
export default class TipTap extends Vue {
  editor: any = null

  @Prop({type: String, default: ""})
  value

  state: any = {}
  data: any = {
    model: {},
  }

  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [StarterKit],
      onUpdate: () => {
        // HTML
        this.$emit("input", this.editor.getHTML())

        // JSON
        // this.$emit('input', this.editor.getJSON())
      },
    })
  }
  beforeDestroy() {
    this.editor.destroy()
  }

  @Watch("value")
  changedValue(value) {
    const isSame = this.editor.getHTML() === value

    // JSON
    // const isSame = this.editor.getJSON().toString() === value.toString()

    if (isSame) {
      return
    }

    this.editor.commands.setContent(this.value, false)
  }
  onInsertEmoIcon(payload: string) {
    this.editor
      .chain()
      .focus()
      .insertContent(payload)
      .run()
  }
  onRichTextChanged(meta) {
    let images = meta.value
    Vue.set(this.data.model, "images", images)
  }
  changeHandler(value) {
    console.log("changeHandler", value)
    this.$emit("input", value)
  }
}
