
import {Component, Vue, Watch} from "vue-property-decorator"
import router from "./../../router"
import {utils} from "./../../shared/utils"
import {siteService} from "./../../services/site-service"
import DomainList from "./../Common/DomainList.vue"

@Component({
  components: {},
})
export default class Site extends DomainList {
  DEFAULT_SORT = "name"
  EDIT_ROUTE_NAME = "SiteEdit"

  data: any = {
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      {text: "ID", value: "siteID"},
      {text: "HR Email", value: "hr_email"},
      {text: "Phone Number", value: "phoneNumber"},
      {text: "Address", value: "address"},
      {text: "Delete", value: "deleteAction", align: "end", sortable: false},
    ],
  }

  pageSetting: any = {
    pagination: {
      fields: "name,siteID,hr_email,merchantNumber,address,phoneNumber,clusters",
    },
  }

  created() {
    this.init(siteService)
  }
}
