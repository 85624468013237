
import {Component, Prop, Vue} from "vue-property-decorator"
import FilterSite from "./Sites.vue"
import FilterDepartment from "./Department.vue"
import FilterTestResult from "./TestResult.vue"

@Component({
  components: {FilterSite, FilterTestResult, FilterDepartment},
})
export default class Filters extends Vue {
  @Prop({default:true})
  showTestResult

  dialog = false
  profile

  created() {
    this.profile = this.$store.getters.profile
  }
  filterUpdated(data) {
    this.$emit("changed", data)
  }
  filterMine() {
    console.log("this.profile.username", this.profile)
    this.$emit("changed", {key: "users", value: [this.profile.username]})
  }
}
