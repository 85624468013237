
import {Component, Vue} from "vue-property-decorator"
import FilterSite from "./Sites.vue"

@Component({
  components: {FilterSite},
})
export default class Sites extends Vue {
  dialog = false
  profile

  created() {
    this.profile = this.$store.getters.profile
  }
  filterUpdated(data) {
    this.$emit("changed", data)
    let payload = {}
    if(data.key){
      payload[data.key] = data.value
    }
    let queryVals = Object.assign({}, this.$route.query, payload)
    if (this.$route.name) {
      this.$router.replace({name: this.$route.name, query: queryVals}).catch(() => {})
    }
  }
}
