
import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import {userManagementService} from "./../../services/user-management-service"
import {utils} from "./../../shared/utils"
import {debounce} from "./../../shared/debounce"
import UserAdminRoleSelect from "../../components/UserAdminRoleSelect.vue"

@Component({components: {UserAdminRoleSelect}})
export default class AdminManagement extends Vue {
  $refs!: {
    userForm: HTMLFormElement
  }

  rules: any = {
    required: (value: any) => !!value || "Required",
    notEmptyString: (value: string) => (value && value.trim() !== "") || "Must not contain only whitespaces",
    maxLength: (value: string, maxLength: number) => (value && value.length <= maxLength) || `Must be less than ${maxLength} characters`,
    minLength: (value: string, minLength: number) => (value && value.length >= minLength) || `Must be at least ${minLength} characters`,
    email: (value: any) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || "E-mail must be valid",
  }

  pageSetting: any = {
    newUser: true,
    showDialog: false,
    loading: {
      savingUser: false,
      table: false,
    },
    pagination: {
      totalItems: 0, // default value
      limit: 10, // default value
      offset: 0, // default value
      page: 1,
      totalPages: 1, // default value
      sort: "name",
      order: "asc",
      q: "",
      total: 0,
      fields: "name,username,email,roles,phone",
    },
    bottomSheet: {
      visible: false,
      //title: "",
      text: "",
      okButtonText: "OK",
      cancelButtonText: "Cancel",
      item: null,
    },
  }

  productFormData: any = {
    _id: "",
    name: "",
    username: "",
    email: "",
    phone: "",
    roles: ["ADMIN"],
  }

  data: any = {
    items: [],
    options: {},
    headers: [
      {text: "Name", value: "name", sortable: true},
      {text: "Username", value: "username", sortable: true},
      {text: "email", value: "email"},
      {text: "phone", value: "phone"},
      {text: "Roles", value: "roles"},
      {text: "Delete", value: "deleteAction", align: "center", sortable: false},
    ],
  }

  async init() {
    await this.getItems()
  }
  selectedRole(meta) {
    if(meta && meta.value && meta.value.length>0){
      Vue.set(this.productFormData, "roles", meta.value||[])
    }
    else{
      Vue.set(this.productFormData, "roles", [])
    }
    
  }

  async getItems() {
    this.pageSetting.loading.table = true

    let result = await userManagementService.getAdministrators(this.pageSetting.pagination)
    if (!result) {
      utils.showSnackbar("Unable to get users")
      this.pageSetting.loading.table = false
      return
    }

    this.data.items = result

    this.pageSetting.pagination.total = result.total
    this.pageSetting.loading.table = false
  }

  @Watch("data.options")
  onPropertyChanged() {
    this.pageSetting.pagination.offset = (this.data.options.page - 1) * this.pageSetting.pagination.limit
    this.pageSetting.pagination.sort = this.data.options.sortBy[0] ? this.data.options.sortBy[0] : "name"
    this.pageSetting.pagination.order = "asc"
    if (this.data.options.sortDesc[0] && this.data.options.sortDesc[0] == true) {
      this.pageSetting.pagination.order = "desc"
    }
    this.init()
  }

  @Watch("data.search")
  async doSearch(value: string) {
    debounce.delay(() => {
      this.pageSetting.pagination.offset = 0
      this.pageSetting.pagination.q = value
      this.init()
    }, 450)
  }

  @Watch("pageSetting.showDialog")
  async onShowDialogPropertyChanged() {
    if (!this.pageSetting.showDialog) {
      // closing dialog

      this.$refs.userForm.resetValidation()
      this.$refs.userForm.reset()
    }
  }

  // parameter: item: the product to be edited. If this form is opened as create (new product) form, set item as null
  async showUserForm(item: any) {
    if (!item) {
      this.pageSetting.newUser = true
    } else {
      this.pageSetting.newUser = false

      this.productFormData._id = item._id
      this.productFormData.name = item.name
      this.productFormData.username = item.username
      this.productFormData.email = item.email
      this.productFormData.phone = item.phone
    }

    this.pageSetting.showDialog = true
  }

  async save() {
    this.pageSetting.loading.savingUser = true

    if (!this.$refs.userForm.validate()) {
      utils.showSnackbar("User validation failed")
      this.pageSetting.loading.savingUser = false
      return
    }
    if (!this.productFormData.roles || this.productFormData.roles.length<1) {
      utils.showSnackbar("Please select a role")
      this.pageSetting.loading.savingUser = false
      return
    }

    let result
    let item = Object.assign({}, this.productFormData)

    delete item._id

    if (this.pageSetting.newUser) {
      item.password = this.productFormData.password
      result = await userManagementService.createAdminUser(item)
    } else {
      item.userId = this.productFormData._id
      result = await userManagementService.updateAdminUser(item)
    }

    if (!result || result.status === "error") {
      utils.showSnackbar("Unable to save user. " + (result ? result.message : ""))
      this.pageSetting.loading.savingUser = false
      return
    }

    if (this.pageSetting.newUser) {
      let newUser = result
      this.data.items.unshift(result.user)
      this.pageSetting.pagination.total = this.data.items.length
    } else {
      let updatedUser = result

      let existing = this.data.items.findIndex((i) => {
        return i._id == updatedUser._id
      })

      if (existing >= 0 && this.data.items && updatedUser) {
        Vue.set(this.data.items, existing, Object.assign({}, updatedUser))
      }
    }

    this.pageSetting.loading.savingUser = false
    this.pageSetting.showDialog = false

    utils.showSnackbar("User saved")
  }

  showDeleteConfirmationDialog(item: any) {
    this.pageSetting.bottomSheet.visible = false
    this.pageSetting.bottomSheet.item = item
    this.pageSetting.bottomSheet.text = `Are you sure you want to delete ${item.name}?`
    this.pageSetting.bottomSheet.visible = true
  }

  resetBottomSheet() {
    this.pageSetting.bottomSheet.visible = false
    this.pageSetting.bottomSheet.item = null
    this.pageSetting.bottomSheet.text = ""
  }

  async deleteItem() {
    this.pageSetting.bottomSheet.visible = false
    this.pageSetting.loading.table = true

    const item = this.pageSetting.bottomSheet.item
    if (!item || !item._id || item._id === "") {
      utils.showSnackbar("Invalid item or item id to delete")
      this.pageSetting.loading.table = false
      return
    }

    let result = await userManagementService.deleteAdministrator({_id: item._id})
    if (!result || result.status === "error") {
      utils.showSnackbar("Unable to delete user. " + (result ? result.message : ""))
      this.pageSetting.loading.table = false
      return
    }

    let existing = this.data.items.findIndex((i) => {
      return i._id == item._id
    })

    if (existing >= 0 && this.data.items) {
      Vue.delete(this.data.items, existing)
    }

    this.pageSetting.loading.table = false
    utils.showSnackbar(`${item.name} deleted`)
  }

  // parameter: roles: roles is an array
  formatRolesColumn(roles) {
    return utils.formatRolesColumn(roles)
  }
}
