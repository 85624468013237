import Component from "vue-class-component"
Component.registerHooks(["beforeRouteEnter", "beforeRouteLeave", "beforeRouteUpdate"])

import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import vuetify from "./plugins/vuetify"
import AppDotty from "./layouts/AppDotty.vue"
import SimpleLayout from "./layouts/SimpleLayout.vue"
import PublicLayout from "./layouts/PublicLayout.vue"
import EmptyLayout from "./layouts/EmptyLayout.vue"
import VueApexCharts from "vue-apexcharts"
Vue.use(VueApexCharts)

Vue.component("apexchart", VueApexCharts)

import moment from "moment"
import {userService} from "./services/user-service"
import {imageUploadService} from "./services/image-upload"
import {utils} from "./shared/utils"

Vue.config.productionTip = false

Vue.filter("filter", function(array: any = [], match: any, field: any) {
  let res = array.filter((item) => {
    let fieldArr = field.split(".") //max dept is 4 levels
    if (fieldArr.length == 1) {
      return item[fieldArr[0]] == match
    } else if (fieldArr.length == 2) {
      return item[fieldArr[0]][fieldArr[1]] == match
    } else if (fieldArr.length == 3) {
      return item[fieldArr[0]][fieldArr[1]][fieldArr[2]] == match
    } else if (fieldArr.length == 4) {
      return item[fieldArr[0]][fieldArr[1]][fieldArr[2]][fieldArr[3]] == match
    }
  })
  return res
})
Vue.filter("serverImage", function(value: any) {
  if (!value) {
    return ""
  }
  if (value.indexOf("http") == 0) {
    return value
  } else {
    return imageUploadService.getImagePath(value)
  }
})
Vue.filter("FormatPerc", function(value: any) {
  if (!value) {
    return 0
  }
  return Math.floor((value * 10000) / 100)
})
Vue.filter("capitalise", function(value: any) {
  if (!value) {
    return ""
  }

  return value.charAt(0).toUpperCase() + value.slice(1)
})
Vue.filter("key2human", function(value: any) {
  if (!value) {
    return ""
  }
  return value.replace(/_/g, " ")
})
Vue.filter("pad02", function(value: any) {
  if (typeof value == "undefined") {
    return ""
  }

  return String(value).padStart(2, "0")
})
Vue.filter("initials", function(value: any) {
  if (!value) {
    return ""
  }

  return utils.getInitials(value)
})
Vue.filter("max", function(text: any, max: any) {
  if (!text || !max) {
    return text
  }

  return text.substr(0, max) + (text.length > max ? "..." : "")
})
Vue.filter("para", function(text: any) {
  if (!text) {
    return ""
  }
  return text.replace(/\n/g, "<br/>")
})
Vue.filter("html2para", function(text: any) {
  if (!text) {
    return ""
  }
  return text.replace(/<\/?[^>]+>/gi, " ")
})
Vue.filter("FormatDateTime", function(value: any) {
  if (!value) {
    return ""
  }

  return moment(value).format("DD/MM/YYYY HH:mm")
})
Vue.filter("FormatDateTimeLong", function(value: any) {
  if (!value) {
    return ""
  }

  return moment(value).format("dddd, DD MMM, YYYY / h:mma")
})
Vue.filter("FormatTime", function(value: any) {
  if (!value) {
    return ""
  }

  return moment(value, "HH:mm:ss").format("h:mma")
})
Vue.filter("DateTimeShort", function(value: any) {
  if (!value) {
    return ""
  }
  return moment(value).format("D MMM, h:mma")
})
Vue.filter("DateShort", function(value: any) {
  if (!value) {
    return ""
  }
  return moment(value).format("DD MMM YY")
})
Vue.filter("DateTimeShortYear", function(value: any) {
  if (!value) {
    return ""
  }
  return moment(value).format("D MMM YY, h:mma")
})

Vue.filter("FormatDateLong", function(value: any) {
  if (!value) {
    return ""
  }

  return moment(value).format("dddd DD MMM, YYYY")
})
Vue.filter("FormatDate", function(value: any) {
  if (!value) {
    return ""
  }

  return moment(value).format("DD MMM YYYY")
})

Vue.filter("fromNow", function(value: any) {
  if (!value) {
    return ""
  }

  return moment(value).fromNow()
})

Vue.filter("toNow", function(value: any) {
  if (!value) {
    return ""
  }

  return moment(value).toNow()
})

Vue.filter("FormatMoney", function(value: any) {
  if (!value) {
    return new Intl.NumberFormat("en-us", {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(0)
  }

  return new Intl.NumberFormat("en-us", {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(value)
})

Vue.filter("FormatNumber", function(value: any) {
  if (!value) {
    return new Intl.NumberFormat("en-us", {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(0)
  }

  return new Intl.NumberFormat("en-us", {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(value)
})
Vue.filter("FormatPerc", function(value: any) {
  if (!value) {
    return 0
  }
  return Math.floor((value * 10000) / 100)
})

async function initializeApp() {
  await userService.initUser()
}

initializeApp().then((_) => {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app")
})

Vue.component("app-layout", AppDotty)
Vue.component("simple-layout", SimpleLayout)
Vue.component("public-layout", PublicLayout)
Vue.component("empty-layout", EmptyLayout)
