
import {Component, Vue, Watch} from "vue-property-decorator"
import router from "../../router"
import {utils} from "../../shared/utils"
import {staffService} from "../../services/staff-service"
import DomainList from "../Common/DomainList.vue"
import Filters from "./Filters/Filters.vue"

@Component({
  components: {Filters},
})
export default class Staff extends DomainList {
  DEFAULT_SORT = "name"
  EDIT_ROUTE_NAME = "StaffEdit"

  data: any = {
    profile: {},

    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
        groupable: false,
      },
      {text: "Email", value: "email", groupable: false},
      {text: "Emp#", value: "employeeNo", groupable: false},
      {text: "Mall", value: "site.siteID", groupable: false},
      {text: "Department", value: "department.name", groupable: false},
      {text: "HOD", value: "hod", groupable: false},
      {text: "Delete", value: "deleteAction", align: "end", sortable: false, groupable: false},
    ],
  }

  pageSetting: any = {
    pagination: {
      fields: "name,department,hod,email,site,employeeNo",
    },
    filters: {},
  }

  created() {
    this.data.profile = this.$store.getters.profile

    this.init(staffService)
  }

  async getListFilter() {
    return this.pageSetting.filters
  }

  filterUpdated(data) {
    console.log("filterUpdated", data)
    if (data.key == "department") {
      this.pageSetting.filters["department"] = data.value
      this.getItems()
    }
    if (data.key == "siteIDs") {
      this.pageSetting.filters["siteIDs"] = data.value
      this.getItems()
    }
  }
}
