
import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import {covidTestResultService} from "../../services/covid-test-result-service"
import BaseTimer from "../../components/BaseTimer.vue"
import DataItem from "../../components/DataItem.vue"

@Component({
  components: {DataItem, BaseTimer},
})
export default class Header extends Vue {
  @Prop({required: true})
  item

  data: any = {
    item: {},
    items: [],
  }

  created() {
    Vue.set(this.data, "item", this.item)
    this.init()
  }

  @Watch("item")
  changeOnItem(item){
    Vue.set(this.data, "item", item)
    this.init()
  }

  async init() {
    if (this.data.item.staff && this.data.item.staff._id) {
      let items = await covidTestResultService.loadHistory(this.data.item.staff._id)
      console.log("items", items)
      if (items.data) {
        this.data.items = items.data
      }
    }
  }

  view(id) {
    this.$router.push({name: "CovidTestResultView", params: {id: id}}).catch(()=>{})
    this.$emit("reloadForId", id)
  }
}
