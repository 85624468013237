
import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import moment from "moment"
@Component({
  components: {},
})
export default class BaseTimer extends Vue {
  days: any = 0
  hours: any = 0
  mins: any = 0
  secs: any = 0
  neg: any = false

  @Prop({required: true})
  value

  @Prop({default: false})
  sec

  @Prop({default: false})
  small

  @Prop({})
  large

  @Prop({default:false})
  dueIn

  @Prop({default: "black--text"})
  color

  timerInterval: any = null

  created() {
    this.timerInterval = setInterval(() => {
      this.compute()
    }, 1000)
  }
  beforeDestroy() {
    clearInterval(this.timerInterval)
  }

  compute() {
    const datePast: any = moment(this.value).toDate()
    const dateNow: any = new Date()
    this.secs = Math.floor((datePast-dateNow) / 1000)
    this.neg = this.secs<0?true:false
    if(!this.dueIn){
      this.neg = !this.neg
    }
    this.secs = Math.abs(this.secs)
    this.mins = Math.floor(this.secs / 60)
    this.hours = Math.floor(this.mins / 60)
    this.days = Math.floor(this.hours / 24)
    this.hours = this.hours - (this.days * 24)
    this.mins = this.mins - this.days * 24 * 60 - this.hours * 60
    this.secs = this.secs - this.days * 24 * 60 * 60 - this.hours * 60 * 60 - this.mins * 60
    
  }

}
