
import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import {siteService} from "./../../../services/site-service"
import {cacheSrv} from "./../../../services/cache"
import eventBus from "../../../eventBus"
import moment from "moment"
import {dateFilterCommonConfig} from "./../../../shared/utils"

@Component({
  components: {},
})
export default class Sites extends Vue {
  @Prop({default: false})
  showPreset

  @Prop({default: "Last 7 days"})
  defaultPreset

  datePeriodOptions = dateFilterCommonConfig.quickArray

  data: any = {
    startDatePicker: false,
    endDatePicker: false,
    filter: {
      startDate: moment()
        .add(-30, "days")
        .format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    },
  }

  async created() {
    this.datePeriodOptions = dateFilterCommonConfig.quickArray
    if (this.$route.query["startDate"]) {
      let values = this.$route.query["startDate"]
      this.data.filter.startDate = values
    }
    if (this.$route.query["endDate"]) {
      let values = this.$route.query["endDate"]
      this.data.filter.endDate = values
    } 
    this.applyDateForOption(this.data.quickOptions)
    this.confirmed()
  }
  applyDateForOption(item) {
    if (!item) {
      return
    }
    let key = item.key
    let value = item.value
    this.data.filter.startDate = moment(value[0]).format("YYYY-MM-DD")
    this.data.filter.endDate = moment(value[1]).format("YYYY-MM-DD")
    this.data.filter.preset = key
    this.confirmed()
  }

  @Watch("data.filter.endDate")
  updatedED(date) {
    this.data.filter.endDate = date
    this.confirmed()
  }
  @Watch("data.filter.startDate")
  updatedSD(date) {
    this.data.filter.startDate = date
    this.confirmed()
  }
  confirmed() {
    this.$emit("changed", {key: "range", value: this.data.filter})
  }
}
