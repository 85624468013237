
import {Component, Vue, Watch} from "vue-property-decorator"
import router from "./../../router"
import {utils, rules} from "./../../shared/utils"
import {BaseDomainService} from "../../services/base-domain-service"
import moment from "moment"

@Component({
  components: {},
})
export default class DomainEdit extends Vue {
  INDEX_ROUTE = ""
  EDIT_ROUTE_NAME = ""
  rules = rules

  form: any = {
    valid: false,
  }
  pageSetting: any = {
    minDate: moment().format("YYYY-MM-DD"),
    original: null,
    disabled: true,
    loading: {
      editForm: false,
      saving: false,
    },
    pagination: {
      offset: {},
      limit: 10,
    },
    tab: null,
    tabForm: null,
    itemId: null,
    bottomSheet: {
      visible: false,
      text: "",
      okButtonText: "OK",
      cancelButtonText: "Cancel",
    },
  }

  data: any = {
    active: true,
    isDeleted: false,
  }

  domainService

  init(domainService) {
    this.domainService = domainService
    this.loadPage()
  }

  async loadPage() {
    const itemId = String(this.$route.params.id)

    if (itemId !== "0" && itemId !== "") {
      this.data._id = itemId
      await this.getItem(itemId)
    } else {
      this.onNewForm()
      this.showEditableForm()
    }
  }
  async getItem(siteId: string) {
    this.pageSetting.loading.editForm = true

    let result = await this.domainService.getItem(siteId)
    if (!result || result.error || result.data.length === 0) {
      utils.showSnackbarError("Unable to get site. " + (result ? result.errorMessage : ""))
      this.pageSetting.loading.editForm = false
      return
    }

    const item = this.afterLoad(result.data)
    this.data = Object.assign({}, this.data, item)

    this.onItemLoaded()
    this.pageSetting.loading.editForm = false
  }

  onItemLoaded(){}
  afterLoad(resultItem){
    return resultItem
  }

  validateSave() {
    return true
  }

  async save() {
    this.pageSetting.loading.saving = true

    if (!this.form.valid) {
      utils.showSnackbarError("Error! Please complete all required fields and try again")
      this.pageSetting.loading.saving = false
      return
    }
    if (!this.validateSave()) {
      return
    }

    this.beforeSave()
    const updatedItem = Object.assign({}, this.data)

    let result
    if (this.data._id) {
      result = await this.domainService.updateItem(updatedItem)
    } else {
      result = await this.domainService.createItem(updatedItem)
    }

    if (!result || result.error) {
      utils.showSnackbarError("Unable to save item. " + (result ? result.errorMessage : ""))

      this.pageSetting.loading.saving = false
      return
    }

    result.data = this.afterSave(result.data)

    if (!this.data._id) {
      this.$router.replace({name: this.EDIT_ROUTE_NAME, params: {id: result.data._id}}).catch((err) => {})
    }
    Vue.set(this, "data", result.data)
    this.pageSetting.original = null

    this.pageSetting.loading.saving = false
    utils.showSnackbar("Item saved")
    this.pageSetting.disabled = true
  }

  beforeSave() {
    //overwrite if you want to manipulate object before save
  }
  afterSave(payload) {
    //overwrite if you want to manipulate object after save
    return payload
  }

  showDeleteConfirmationDialog() {
    this.pageSetting.bottomSheet.visible = false
    this.pageSetting.bottomSheet.text = `Are you sure you want to delete this item?`
    this.pageSetting.bottomSheet.visible = true
  }

  resetBottomSheet() {
    this.pageSetting.bottomSheet.visible = false
    this.pageSetting.bottomSheet.text = ""
  }

  async deleteItem() {
    this.pageSetting.bottomSheet.visible = false
    this.pageSetting.loading.saving = true

    if (!this.data._id) {
      utils.showSnackbar("Invalid item or item id to delete")
      this.pageSetting.loading.saving = false
      return
    }

    let result = await this.domainService.deleteItem(this.data._id)
    if (!result || result.error) {
      utils.showSnackbarError("Unable to delete item. " + (result ? result.errorMessage : ""))
      this.pageSetting.loading.saving = false
      return
    }

    this.pageSetting.loading.saving = false

    this.returnToIndex()
    utils.showSnackbar(`Item deleted successfully`)
  }

  showEditableForm() {
    this.pageSetting.disabled = false
  }
  onNewForm(){}

  editButtonClicked() {
    this.pageSetting.original = Object.assign({}, this.data)
    this.showEditableForm()
  }

  closeButtonClicked() {
    if (this.pageSetting.loading.saving) {
      // saving in progress
      utils.showSnackbar("Saving in progress... please wait")
      return
    }

    if (this.data._id) {
      this.data = this.pageSetting.original
      this.pageSetting.disabled = true
    } else {
      // this is a create form, so redirect back to site list page
      this.returnToIndex()
    }
  }

  returnToIndex() {
    router.push({name: this.INDEX_ROUTE})
  }

  backButtonClicked() {
    this.$router.back()
  }
}
