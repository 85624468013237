import { baseService } from "./base-service";

class ImageUploadService {
  uploadsUrl = process.env.VUE_APP_HR_PUBLIC_IMAGE_URL; 
  URL_PREFIX = "file-items/"

  upload(file, fileItemId, onUploadProgress){
    let formData = new FormData();

    formData.append("file", file);

    return baseService.postUpload(this.URL_PREFIX + fileItemId + "/upload", formData, {
      onUploadProgress
    });
  }

  getBasePath(){
    return baseService.serverApiUrl
  }

  getImagePath(imageName, thumb=null){
    return this.uploadsUrl + imageName
  }

  getUrl(urlSuffix: string) {
      return `${baseService.serverApiUrl}${urlSuffix}`
  }
}

export const imageUploadService = new ImageUploadService();
