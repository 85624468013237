
import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import {siteService} from "../../../services/site-service"
import {departmentService} from "../../../services/department-service"

@Component({
  components: {},
})
export default class SitesAndDepts extends Vue {
  dialog = false

  data: any = {
    model: [],
    dept: [],
  }

  items: any = []
  depts: any = []

  async created() {
    let result = await siteService.getItems({fields: "siteID"}, {limit: 100, sort: "siteID"})
    Vue.set(this, "items", result.data ? result.data.docs : [])

    result = await departmentService.getItems({fields: "name,site.siteID"}, {limit: 1000, sort: "name"})
    Vue.set(this, "depts", result.data ? result.data.docs : [])
  }
  confirmed() {
    this.$emit("changed", {key: "siteIDs", value: this.data.model})
    this.dialog = false
  }
  confirmedDept() {
    this.$emit("changed", {key: "department", value: this.data.dept})
    this.dialog = false
  }
}
