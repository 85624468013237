import {BaseDomainService} from "./base-domain-service"
import {socketService} from "./socket-service"

class CovidTestResultService extends BaseDomainService {
  URL_PREFIX = "covid-test-result/"

  async loadView(itemId: string) {
    return await this.get(`${this.URL_PREFIX}${itemId}/view`)
  }
  async loadHistory(staffId) {
    return await this.get(`${this.URL_PREFIX}history/${staffId}`)
  }
  async loadStaffTestsForDateRange(payload: any) {
    return await this.get(`${this.URL_PREFIX}tests/groupby/for-date`, payload)
  }
}

export const covidTestResultService = new CovidTestResultService()
