
import {Component, Vue, Watch} from "vue-property-decorator"
import {utils, rules} from "../../shared/utils"
import {closeContactCategoryService} from "../../services/close-contact-category-service"
import DomainEdit from "../Common/DomainEdit.vue"
import {cacheSrv} from "../../services/cache"

@Component({
  components: {},
})
export default class CloseContactCategoryEdit extends DomainEdit {
  INDEX_ROUTE = "CloseContactCategory"
  EDIT_ROUTE_NAME = "CloseContactCategoryEdit"

  data: any = {
    name: "",
    code: "",
    active: true,
    isDeleted: false,
  }

  cluster: any = {
    clusters: [],
    selected: {},
  }

  created() {
    this.init(closeContactCategoryService)
  }

  validateSave() {
    if (!this.data.name || this.data.name.trim() == "") {
      utils.showSnackbarError("Name is required")
      this.pageSetting.loading.saving = false
      return false
    }
    return true
  }

  afterSave(payload) {
    cacheSrv.remove(closeContactCategoryService.CACHE_KEY)
    return payload
  }
}
