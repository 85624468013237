
import {Component, Vue, Watch} from "vue-property-decorator"
import {covidTestReasonService} from "../../services/covid-test-reason-service"
import DomainList from "../Common/DomainList.vue"

@Component({
  components: {},
})
export default class Site extends DomainList {
  DEFAULT_SORT = "name"
  EDIT_ROUTE_NAME = "CovidTestReasonEdit"

  data: any = {
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      {text: "Close Contact", value: "closeContact"},
      {text: "Delete", value: "deleteAction", align: "end", sortable: false},
    ],
  }

  pageSetting: any = {
    pagination: {
      fields: "name,closeContact",
    },
  }

  created() {
    this.init(covidTestReasonService)
  }
}
