
import {Component, Vue} from "vue-property-decorator"
import store from "./../../store"
import router from "./../../router"
import {cacheSrv} from "./../../services/cache"
import {userService} from "./../../services/user-service"
import axios from "axios"

@Component({
  components: {},
})
export default class Login extends Vue {
  ssoApiUrl = process.env.VUE_APP_HR_SSO_API
  homeUrl = process.env.VUE_APP_HR_HOMEURL

  CACHE_KEY_TOKEN = "bpa_token"
  CACHE_KEY_PROFILE = "bpa_user"

  user = {
    username: "",
    password: "",
  }

  data: any = {
    step: "login",
  }

  submitted = false
  processing = false
  errorMessage = ""
  passwordError = false
  error = {
    msg: "",
  }

  constructor() {
    super()
  }

  errorPass() {
    this.passwordError = true
    setTimeout(() => {
      this.passwordError = false
    }, 1600)
  }

  async resetPassword() {
    this.errorMessage = ""
    this.processing = true
    let result
    if (!this.user.username) {
      this.errorPass()
      this.errorMessage = "Please enter username"
      this.processing = false
      return
    }

    try {
      result = await axios.post(`${this.ssoApiUrl}/api/admin/request-reset-password`, {
        username: this.user.username.trim(),
        targeturl: this.homeUrl,
      })
    } catch (e) {
      console.log("error", e)
      this.errorPass()
      console.log("Error while reseting pass")
      this.processing = false
      this.errorMessage = "Error procesing your request. Please check your internet connection and try again"
      return
    }

    this.processing = false
    if (result.data) {
      let data = result.data
      console.log("data", data)

      if (data.error == true) {
        this.errorPass()
        console.log("Error in resettng pass")
        this.errorMessage = data.message
        return
      }

      this.errorMessage = ""
      this.data.step = "reset-password-done"
    } else {
      console.log("Error in validating username. No data")
    }
  }

  async authenticate() {
    this.errorMessage = ""
    this.processing = true
    let result

    if (!this.user.password) {
      this.errorPass()
      this.errorMessage = "Please enter username and password"
      this.processing = false
      return
    }

    try {
      result = await axios.post(`${this.ssoApiUrl}/api/admin/login`, {
        username: this.user.username.trim(),
        password: this.user.password.trim(),
      })
    } catch (e) {
      console.log("error", e)
      this.errorPass()
      console.log("Error while login authenticating")
      this.processing = false
      this.errorMessage = "Error procesing your request. Please check your internet connection and try again"
      return
    }

    this.processing = false
    if (result.data) {
      let data = result.data

      if (data["status"] == "error") {
        this.errorPass()
        console.log("Error in validating number")
        this.errorMessage = data.message
        return
      }

      if (data["token"]) {
        //console.log("storing token")
        this.errorMessage = ""
        cacheSrv.cacheEx(this.CACHE_KEY_TOKEN, data["token"], 60 * 60 * 24 * 360) //slighly less then a year
        store.commit(`updateToken`, data["token"])
        userService.initUser()

        setTimeout(function() {
          router.push("/")
        }, 500)
      } else {
        console.log("no token")
        this.errorPass()
      }
    } else {
      console.log("Error in validating username. No data")
    }
  }
}
