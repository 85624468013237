
import {Component, Vue} from "vue-property-decorator"
import moment from "moment"
import {reportService} from "../../services/reports-service"
import eventBus from "../../eventBus"
import FilterSite from "./Filters/Sites.vue"
import FilterDates from "./Filters/Dates.vue"
import TypeTotals from "./Charts/TypeTotals.vue"
import FilterDepartment from "./Filters/Department.vue"

@Component({
  components: {FilterSite, FilterDates, TypeTotals, FilterDepartment},
})
export default class SentimentReport extends Vue {
  remindRefresh = true
  data: any = {
    reportList: [],
    grandTotals: {},
    filter: {
      groupBy: "",
      mall: null,
      tenant: null,
    },
    items: {},
    chartItems: [],
    groupBy: ["Tenant", "Tenant -> Job Post", "Year", "Month", "Week", "Day"],
  }
  pageSettings: any = {
    more: false,
    reportConf: {
      type: "composite",
      collection: "hr_submissions-",
      reportName: "Submissions By Reason",
      _csv: {},
      q: null,
      limit: 100,
      byReason: true,
      args: [{field: "_id", label: "Count", type: "value_count"}],
      filters: [],
      filtersMap: {},
      range: {dateField: "createdAt"},
      columns: [
      ],
    },
  }

  last = {}
  lastRowKey: any = []

  constructor() {
    super()
  }
  created() {}

  search(reset) {
    if (reset) {
      this.pageSettings.more = false
      this.pageSettings.reportConf.after_key = null
      this.last = {}
      this.lastRowKey = []
      this.data.grandTotals = {}
      this.data.reportList = []
    }

    this.load()
  }
  async load() {
    let result = await reportService.submissions(this.pageSettings.reportConf)

    if (!result || (result && result.error == true)) {
      eventBus.$emit("snack", {type: "error", text: "Error loading report data. " + result.message})
      return
    }
    if (result.data) {
      console.log("result.data", result.data)
      let bySite = result.data.reduce((a, c) => {
        if (!a[c.site]) {
          a[c.site] = []
        }
        a[c.site].push({label: c.reason, total: c.total})
        return a
      }, {})

      Vue.set(this.data, "items", bySite)
    }
    this.remindRefresh = false
  }
  filterUpdated(data) {
    if (data.key == "range") {
      this.pageSettings.reportConf.range.startDate = data.value.startDate
      this.pageSettings.reportConf.range.endDate = data.value.endDate
    } else {
      this.updateFilterForKey(data.key, data.value)
    }
    this.remindRefresh = true
  }

  updateFilterForKey(key, val) {
    let xiting = this.pageSettings.reportConf.filters.find((itm) => {
      return itm.field == key
    })
    if (xiting) {
      this.pageSettings.reportConf.filters.forEach((itm) => {
        if (itm.field == key) {
          itm.value = val
        }
      })
    } else {
      this.pageSettings.reportConf.filters.push({field: key, value: val})
    }
    this.pageSettings.reportConf.filtersMap[key] = val
  }
  exportToCSV() {
    this.pageSettings.reportConf.after_key = null
    let withCSV = Object.assign({}, this.pageSettings.reportConf, {csv: {export: true}})
    reportService.submissions(withCSV)
  }

  compositeGroupper(arr, options) {
    let vals = this.pageSettings.reportConf.args.map((i) => i.field)

    let output: any = []
    let keys = options.keys
    console.log("compositeGroupper keys", keys)
    let totals = this.data.totals
    let c = 1
    arr.forEach((row) => {
      let item = Object.assign({}, row)
      let reset = false
      for (let index = 0; index < keys.length; index++) {
        const element = keys[index]
        console.log("ele", element, index)
        let key = row[element]
        if (this.lastRowKey[index] == key && !reset) {
          item[element] = ""
        } else {
          reset = true
          this.lastRowKey[index] = key
        }
      }
      output.push(item)
      vals.forEach((key) => {
        this.data.grandTotals[key] = this.data.grandTotals[key] + (item[key].value ? item[key].value : 0)
      })
    })
    this.data.reportList = [...this.data.reportList, ...output] //...this.data.results,
  }
  enrich(data) {
    let dateFilter = this.pageSettings.reportConf.columns.find((i) => {
      return i.type == "date"
    })

    data.forEach((item) => {
      if (dateFilter) {
        if (dateFilter.interval == "1h") {
          item[dateFilter.label] = moment(item.hour).format("HH")
        }
        if (dateFilter.interval == "1d") {
          item[dateFilter.label] = moment(item[dateFilter.label]).format("DD MMM")
        }
      }
    })
    return data
  }
}
