
import {Component, Prop, Vue} from "vue-property-decorator"
@Component({
  components: {},
})
export default class BaseTimer extends Vue {
  confirmDialog = false

  @Prop({required: true})
  imageUrl
}
