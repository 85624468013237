
import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import {covidTestResultService} from "../../services/covid-test-result-service"
import BaseTimer from "../../components/BaseTimer.vue"
import DataItem from "../../components/DataItem.vue"

@Component({
  components: {DataItem, BaseTimer},
})
export default class Header extends Vue {
  @Prop({required: true})
  item

  data: any = {
    item: {},
  }

  created() {
    Vue.set(this.data, "item", this.item)
  }
  @Watch("item")
  changeOnItem(item){
    Vue.set(this.data, "item", item)
  }
}
