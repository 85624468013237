import {baseService} from "./base-service"
class DashboardService {
  URL_PREFIX = "dashboard/"

  async getDash(payload: any) {
    return await baseService.get(`${this.URL_PREFIX}dash`, payload)
  }
}

export const dashboardService = new DashboardService()
