import {baseService} from "./base-service"
import {baseReportingService} from "./base-reporting-service"

class SearchService {
  URL_PREFIX = "search/"

  async find(payload: any) {
    return await baseReportingService.get(`${this.URL_PREFIX}tickets`, payload)
  }
}

export const searchService = new SearchService()
