
import {Component, Prop, Vue} from "vue-property-decorator"
import {workScheduleService} from "../../services/work-schedule-service"
import {utils} from "./../../shared/utils"
import moment from "moment"

@Component({
  components: {},
})
export default class WorkDaySetup extends Vue {
  types: any = ["WFO", "WFH", "OFF"]

  data: any = {
    value: "",
    base: {},
  }

  @Prop({})
  value

  @Prop({required: true})
  staff

  @Prop({required: true})
  date

  created() {
    this.data.value = String(this.value)
    this.data.base = {
      staffId: this.staff._id,
      name: this.staff.name,
      siteID: this.staff.site.siteID,
      deptId: this.staff.department._id,
      deptName: this.staff.department.name,
      date: this.date,
    }
  }

  async save(type) {
    //TODO - evaluate if this can be done by HR as well as Dept HOD?
    //--------------------------------------------------
    console.log("SAv", type, this.date, this.staff._id)
    let payload = Object.assign({}, this.data.base, {
      type: type,
      _id: this.staff._id + "_" + moment(this.date).format("YYYYMMDD"),
    })
    let res = await workScheduleService.updateItem(payload)
    if (!res || res.error) {
      console.log("Failed to save work schedule")
      utils.showSnackbarError("Failed to save schedule")
      return
    }
    this.data.value = type
    this.$emit("changed", {staffId: this.staff._id, date: this.date, value: type})
  }
}
