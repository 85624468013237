
import {Component, Vue, Watch} from "vue-property-decorator"
import router from "./../../router"
import {utils, rules} from "./../../shared/utils"
import {siteService} from "./../../services/site-service"
import DomainEdit from "./../Common/DomainEdit.vue"
import {cacheSrv} from "./../../services/cache"

@Component({
  components: {},
})
export default class SiteEdit extends DomainEdit {
  INDEX_ROUTE = "Site"
  EDIT_ROUTE_NAME = "SiteEdit"

  data: any = {
    name: "",
    address: "",
    siteID: "",
    phoneNumber: "",
    merchantNumber: "",
    faxNumber: "",
    contact: {},
    active: true,
    isDeleted: false,
  }

  cluster: any = {
    clusters: [],
    selected: {},
  }

  created() {
    this.init(siteService)
  }

  validateSave() {
    if (!this.data.name || this.data.name.trim() == "") {
      utils.showSnackbarError("name is required")
      this.pageSetting.loading.saving = false
      return false
    }
    if (!this.data.siteID || this.data.siteID.trim() == "") {
      utils.showSnackbarError("SiteID is required")
      this.pageSetting.loading.saving = false
      return false
    }
    return true
  }
  afterSave(payload) {
    cacheSrv.remove(siteService.CACHE_KEY)
    return payload
  }
}
