
import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import moment from "moment"
import {utils} from "../../shared/utils"

@Component({
  components: {},
})
export default class CasesByDay extends Vue {
  @Prop({required: true})
  items

  filters: any = {
    data: {},
  }
  data: any = {
    options: {
      grid: {
        show: false,
      },
      chart: {
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 1,
          left: 1,
          blur: 0,
          opacity: 0.2,
        },
        id: "tickets-by-source",
        height: "250",
        events: {
          click: (event, chartContext, config) => {
            // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
            console.log("Clicked", config)
            this.goto(config)
          },
        },
      },
      dataLabels: {
        enabled: true,
      },
      legend: {
        show: false,
      },
      labels: {
        style: {
          fontSize: "10px",
        },
      },
      plotOptions: {
        bar: {
          distributed: true,
        },
      },
      xaxis: {
        categories: [],
      },
    },
    series: [
      {
        name: "Tickets Count",
        data: [],
      },
    ],
  }

  created() {
    this.loadChart()
  }

  @Watch("items")
  refresh(val) {
    this.loadChart()
  }

  async loadChart() {
    let {labels, values} = utils.chartDayFill(this.items, 7, (i=>{return i._id.day}) )
    this.data.options = Object.assign({}, this.data.options, {xaxis: {categories: labels}}) // chart: {height: String(50*labels.length)}
    this.data.series = [{data: values}]
  }
  goto(config) {}
}
