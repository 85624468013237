
import {Component, Vue} from "vue-property-decorator"
import FilterSite from "./Sites.vue"
import FilterDepartment from "./Department.vue"

@Component({
  components: {FilterSite, FilterDepartment},
})
export default class Filters extends Vue {
  dialog = false
  created() {
  }
  filterUpdated(data) {
    this.$emit("changed", data)
  }
}
