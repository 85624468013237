
import {Component, Vue} from "vue-property-decorator"
import store from "./../../store"
import router from "./../../router";
import {cacheSrv} from "./../../services/cache"
import {siteService} from "./../../services/site-service"

@Component({
  components: {},
})
export default class Logout extends Vue {
  CACHE_KEY_TOKEN = "bpa_token"
  CACHE_KEY_PROFILE = "bpa_user"
  loggedout = false
  processing = false

  constructor() {
    super()
  }

  created() {
    // let token = cacheSrv.cached(this.CACHE_KEY_TOKEN)
    // if (!token) {
    //   this.loggedout = true
    // }
    // console.log("token",token);
  }

  login() {
    this.redirectToLogin();
  }

  cancel() {
    router.push("dashboard")
  }

  logout() {
    this.processing = true
    console.log("Local storage cleanup")
    cacheSrv.remove(this.CACHE_KEY_PROFILE)
    cacheSrv.remove(this.CACHE_KEY_TOKEN)
    cacheSrv.remove(siteService.CACHE_KEY)
    cacheSrv.remove(siteService.CACHE_KEY)
    store.commit(`logout`)
    this.redirectToLogin();
    this.processing = false
    this.loggedout = true
  }

  redirectToLogin() {
    router.push("login");
  }
}
