
import {Component, Prop, Vue, Watch} from "vue-property-decorator"

@Component({
  components: {},
})
export default class TestResult extends Vue {
  dialog = false

  data: any = {
    model: [],
  }
  items: any = ["POSITIVE", "NEGATIVE", "INVALID"]

  async created() {
    if (this.$route.query["testResult"]) {
      let values = this.$route.query["testResult"]
      this.data.model = Array.isArray(values) ? values : [values]
    }
    Vue.set(this, "items", this.items)
  }
  confirmed() {
    this.$emit("changed", {key: "testResult", value: this.data.model})
    this.dialog = false
  }
}
