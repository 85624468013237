
import {Component, Vue, Watch} from "vue-property-decorator"
import router from "../../router"
import {utils, rules} from "../../shared/utils"
import {departmentService} from "../../services/department-service"
import {staffService} from "../../services/staff-service"
import DomainEdit from "../Common/DomainEdit.vue"
import {cacheSrv} from "../../services/cache"
import UserAccess from "./../../components/UserAccess.vue"
import {siteService} from "../../services/site-service"

@Component({
  components: {UserAccess},
})
export default class StaffEdit extends DomainEdit {
  INDEX_ROUTE = "Staff"
  EDIT_ROUTE_NAME = "StaffEdit"

  data: any = {
    name: "",
    department: {},
    site: {},
    hod: false,
    active: true,
    isDeleted: false,
  }

  departments: any = []
  sites: any = []
  hods: any = []

  created() {
    this.init(staffService)
    this.loadDepartments()
    this.loadSites()
  }

  onItemLoaded() {
    this.loadHODs()
  }
  async loadHODs() {
    console.log("loadHODs started", this.data.site, this.data.department)
    if (this.data.site && this.data.site.siteID && this.data.department && this.data.department._id) {
      let hods = await staffService.loadHODsForSiteAndDepartment(this.data.site.siteID, this.data.department._id)
      if (hods && !hods.error) {
        this.hods = hods.data
      }
    }
  }
  async loadSites() {
    let sites = await siteService.getItems({}, {limit: 1000})
    this.sites =
      sites.data && sites.data.docs
        ? sites.data.docs.map((i) => {
            return {siteID: i.siteID, _id: i._id}
          })
        : []
  }
  async loadDepartments() {
    let departments = await departmentService.getItems({}, {limit: 1000})

    this.departments =
      departments.data && departments.data.docs
        ? departments.data.docs.map((i) => {
            return {name: i.name, _id: i._id}
          })
        : []
  }

  get roles() {
    let roleArray = ["STAFF"]
    if (this.data.hod) {
      roleArray.push("HOD")
    }
    return roleArray
  }

  validateSave() {
    if (!this.data.department) {
      utils.showSnackbarError("Please select department")
      this.pageSetting.loading.saving = false
      return false
    }
    if (!this.data.site) {
      utils.showSnackbarError("Please select site")
      this.pageSetting.loading.saving = false
      return false
    }
    if (!this.data.name) {
      utils.showSnackbarError("Please enter staff name")
      this.pageSetting.loading.saving = false
      return false
    }
    if (this.data.hod && !this.data.email) {
      utils.showSnackbarError("HOD requires Email account for notification purposes")
      this.pageSetting.loading.saving = false
      return false
    }
    return true
  }

  afterSave(payload) {
    cacheSrv.remove(staffService.CACHE_KEY)
    return payload
  }
  view() {
    this.$router.push({name: "StaffView", params: {id: this.data._id}}).catch(() => {})
  }
}
