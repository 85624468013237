
import {Component, Vue, Watch} from "vue-property-decorator"
import router from "./../../router"
import {utils, rules} from "./../../shared/utils"
import {debounce} from "./../../shared/debounce"
import {BaseDomainService} from "../../services/base-domain-service"

@Component({
  components: {},
})
export default class DomainList extends Vue {
  DEFAULT_SORT = "_id"
  EDIT_ROUTE_NAME = ""

  data: any = {
    items: [],
    singleSelect: true,
    selected: [],
    options: {},
    headers: [],
  }

  pageSetting: any = {
    loading: {
      table: false,
    },
    pagecontrol: false,
    pagination: {
      totalItems: 0,
      limit: 15,
      offset: 0,
      page: 1,
      totalPages: 1,
      sort: this.DEFAULT_SORT,
      order: "asc",
      fields: "",
    },
    search: "",
    bottomSheet: {
      visible: false,
      text: "",
      okButtonText: "OK",
      cancelButtonText: "Cancel",
      item: null,
    },
  }

  queryFilter: any = {}
  domainService

  created() {
    this.queryFilter = Object.assign({}, this.$route.query)
    if (this.$route.query.q) {
      this.pageSetting.search = String(this.$route.query.q)
    }
    if (this.$route.query.order) {
      this.pageSetting.pagination.order = this.$route.query.order
    }
    if (this.$route.query.limit) {
      this.pageSetting.pagination.limit = Number(this.$route.query.limit)
    }
    if (this.$route.query.offset) {
      this.pageSetting.pagination.offset = Number(this.$route.query.offset)
    }
    if (this.$route.query.sort) {
      this.pageSetting.pagination.sort = this.$route.query.sort
    }
  }

  init(domainService) {
    this.domainService = domainService
  }

  async getItems() {
    debounce.delay(async () => {
      this.pageSetting.loading.table = true

      this.data.items = []

      let filter = await this.getListFilter()
      console.log("getItems filter", filter)
      let result = await this.domainService.getItems(filter, this.pageSetting.pagination)
      if (!result || result.error) {
        utils.showSnackbar("Unable to get item. " + (result ? result.errorMessage : ""))
        this.pageSetting.loading.table = false
        return
      }

      let items = result.data && result.data.docs ? result.data.docs : []
      items = await this.onLoaded(items)
      this.data.items = items
      this.pageSetting.pagination.totalItems = result.data ? result.data.total : 0
      this.pageSetting.loading.table = false
    }, 288)
  }
  async getListFilter() {
    let comboFilter = Object.assign({}, this.queryFilter)
    return comboFilter
  }
  async onLoaded(items) {
    return items
  }

  async deleteItem() {
    this.pageSetting.bottomSheet.visible = false
    this.pageSetting.loading.table = true

    const item = this.pageSetting.bottomSheet.item
    if (!item || !item._id || item._id === "") {
      utils.showSnackbar("Invalid item or item id to delete")
      this.pageSetting.loading.table = false
      return
    }

    let result = await this.domainService.deleteItem(item._id)
    if (!result || result.error) {
      utils.showSnackbar("Unable to delete item. " + (result ? result.errorMessage : ""))
      this.pageSetting.loading.table = false
      return
    }

    let index = this.data.items.findIndex((i) => {
      return i._id == item._id
    })
    if (index >= 0) {
      Vue.delete(this.data.items, index)
    }

    this.resetBottomSheet()
    this.pageSetting.loading.table = false
    utils.showSnackbar(`Item deleted successfully`)
  }

  @Watch("data.options")
  async onPropertyChanged() {
    this.pageSetting.pagination.limit = this.data.options.itemsPerPage ? Number(this.data.options.itemsPerPage) : 15
    if (this.data.options.page > 1) {
      this.pageSetting.pagination.offset = (this.data.options.page - 1) * this.pageSetting.pagination.limit
    } else {
      if (this.pageSetting.pagecontrol) {
        this.pageSetting.pagination.offset = (this.data.options.page - 1) * this.pageSetting.pagination.limit
      } else {
        this.data.options.page = 1+ (this.pageSetting.pagination.offset / this.pageSetting.pagination.limit)
      }
    }
    if (this.data.options.sortBy[0] != null) {
      this.pageSetting.pagination.sort = this.data.options.sortBy[0]
    }
    if (this.data.options.sortDesc[0] != null) {
      if (this.data.options.sortDesc[0] == true) {
        this.pageSetting.pagination.order = "desc"
      } else {
        this.pageSetting.pagination.order = "asc"
      }
    }

    let queryVals = Object.assign({}, this.$route.query, {
      order: this.pageSetting.pagination.order,
      limit: this.pageSetting.pagination.limit,
      offset: this.pageSetting.pagination.offset,
      sort: this.pageSetting.pagination.sort,
    })
    if (this.$route.name) {
      this.$router.replace({name: this.$route.name, query: queryVals}).catch(() => {})
    }

    this.getItems()
    this.pageSetting.pagecontrol = true //start relying on pagination control after initial load
  }

  showDeleteConfirmationDialog(item: any) {
    this.pageSetting.bottomSheet.visible = false
    this.pageSetting.bottomSheet.item = item
    this.pageSetting.bottomSheet.text = `Are you sure you want to delete ${item.name}?`
    this.pageSetting.bottomSheet.visible = true
  }

  resetBottomSheet() {
    this.pageSetting.bottomSheet.visible = false
    this.pageSetting.bottomSheet.item = null
    this.pageSetting.bottomSheet.text = ""
  }

  async loadPage() {
    console.log("loadPage dfault")
  }

  @Watch("pageSetting.search")
  async doSearch(value: string) {
    debounce.delay(() => {
      this.pageSetting.pagination.offset = 0
      this.pageSetting.pagination.q = value

      let queryVals = Object.assign({}, this.$route.query, {q: value})
      if (this.$route.name) {
        this.$router.replace({name: this.$route.name, query: queryVals}).catch(() => {})
      }

      this.getItems()
    }, 450)
  }

  redirectToEditPage(id: string) {
    router.push({name: this.EDIT_ROUTE_NAME, params: {id: id}})
  }

  redirectToCreatePage() {
    router.push({name: this.EDIT_ROUTE_NAME, params: {id: "0"}})
  }
}
