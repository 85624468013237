
import {Component, Vue} from "vue-property-decorator"
import {dashboardService} from "./../../services/dashboard-service"
import CasesByReason from "./CasesByReason.vue"
import CasesByDay from "./CasesByDay.vue"
import Filters from "./Filters/Filters.vue"
import {debounce} from "./../../shared/debounce"

@Component({
  components: {CasesByReason, CasesByDay, Filters},
})
export default class Home extends Vue {
  data: any = {
    activePositive: 0,
    recentPositive: [],
    recentCleared: [],
    byReason: [],
    totalSubsByDay: [],
  }

  filters: any = {}

  async created() {
    this.init()
  }
  async init() {
    this.refresh()
  }

  async refresh() {
    debounce.delay(async () => {

      let queryVals = Object.assign({}, this.$route.query, this.filters)

      console.log("this.filters", queryVals)
      let dash = await dashboardService.getDash(queryVals)
      console.log("dash", dash)
      this.data.recentPositive = dash.recentPositive
      this.data.activePositive = dash.activePositive
      this.data.recentCleared = dash.recentCleared
      this.data.byReason = dash.byReason
      this.data.totalSubsByDay = dash.totalSubsByDay
      this.data.totalSubsToday = dash.totalSubsToday
      this.data.totalSubsPeriod = dash.totalSubsPeriod

      let byResult: any = dash.totalPerc.reduce((a, c) => {
        if (!c["testResult"]) {
          c["testResult"] = "NOT-TESTED"
        }
        a[c["testResult"]] = c.count
        a["TOTAL"] = (a["TOTAL"] || 0) + c.count
        return a
      }, {})

      console.log("byResult", byResult)
      if (byResult["TOTAL"]) {
        this.data.totalPerc = {
          POSITIVE: Math.ceil(((byResult["POSITIVE"] || 0) / byResult["TOTAL"]) * 100),
          "NOT-TESTED": byResult["NOT-TESTED"],
        }
      } else {
        this.data.totalPerc = {
          POSITIVE: 0,
          "NOT-TESTED": 100,
        }
      }
    }, 480)
  }

  showStaff(staffMeta) {
    if (staffMeta && staffMeta._id) {
      this.$router.push({name: "StaffView", params: {id: staffMeta._id}}).catch(() => {})
    }
  }

  filterUpdated(data) {
    console.log("data", data)
    if (!data.value) {
      Vue.delete(this.filters, data.key)
      delete this.filters[data.key]
    } else {
      Vue.set(this.filters, data.key, data.value)
    }
    console.log("this.filters pre refresh", this.filters)
    this.refresh()
  }
}
