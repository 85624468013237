
import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import {siteService} from "./../../../services/site-service"

@Component({
  components: {},
})
export default class Sites extends Vue {
  dialog = false

  data: any = {
    model: [],
  }

  items: any = []

  async created() {
    let result = await siteService.getItems({fields: "siteID"}, {limit: 100, sort: "siteID"})
    if (this.$route.query["siteIDs"]) {
      let values = this.$route.query["siteIDs"]
      this.data.model = Array.isArray(values) ? values : [values]
    }
    Vue.set(this, "items", result.data ? result.data.docs : [])
  }
  confirmed() {
    this.$emit("changed", {key: "siteIDs", value: this.data.model})
    this.dialog = false
  }
}
