
import {Component, Vue} from "vue-property-decorator"
import router from "./../../router";
import axios from "axios"

@Component({
  components: {},
})
export default class Login extends Vue {
  ssoApiUrl = process.env.VUE_APP_HR_SSO_API
  CACHE_KEY_TOKEN = "bpa_token"
  CACHE_KEY_PROFILE = "bpa_user"

  user = {
    password: "",
    confirmPassword: "",
  }
  submitted = false
  processing = false
  errorMessage = ""
  passwordError = false
  error = {
    msg: "",
  }

  params
  constructor() {
    super()
  }

  created() {
    this.params = this.$route.params
    if (!this.params.key) {
      console.log("You have no key")
    }
  }

  errorPass() {
    this.passwordError = true
    setTimeout(() => {
      this.passwordError = false
    }, 1600)
  }
  async resetPassword() {

    this.errorMessage = ""
    this.processing = true
    let result
    if (!this.user.password) {
      this.errorPass()
      this.errorMessage = "Please enter username and password"
      this.processing = false
      return
    }

    try {
      result = await axios.post(`${this.ssoApiUrl}/api/admin/reset-password`, {
        password: this.user.password.trim(),
        confirmPassword: this.user.confirmPassword.trim(),
        key: this.params.key
      })
    } catch (e) {
      console.log("error", e)
      this.errorPass()
      console.log("Error while login authenticating")
      this.processing = false
      this.errorMessage = "Error procesing your request. Please check your internet connection and try again"
      return
    }
    this.processing = false
    if (result.data) {
      let data = result.data
      console.log("data", data)
      if (data.error==true) {
        this.errorPass()
        console.log("Error in processing resetPassword ")
        this.errorMessage = data.message
        return
      }
      setTimeout(function() {
        router.push("/login")
      }, 500)
    } else {
      console.log("Error in validating username. No data")
    }
  }
}
