import { BaseDomainService } from './base-domain-service';

class WorkScheduleService extends BaseDomainService{
  URL_PREFIX = "work-schedule/";
  CACHE_KEY = "work-schedule-list"

  async loadStaffWorkScheduleForDateRange(siteID, departmentId, from, to) {
    return await this.get(`${this.URL_PREFIX}site/${siteID}/department/${departmentId}/${from}/${to}`)
  }
}
export const workScheduleService = new WorkScheduleService();
