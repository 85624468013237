
import {Component, Prop, Vue} from "vue-property-decorator"
import moment from "moment"
import {covidTestResultService} from "./../../services/covid-test-result-service"
import DomainList from "../Common/DomainList.vue"
import Filters from "./Filters/Filters.vue"
import eventBus from "../../eventBus"
import FilterDates from "./Filters/Dates.vue"
import {userService} from "../../services/user-service"
import router from "@/router"

@Component({
  components: {Filters, FilterDates},
})
export default class CovidTestResult extends DomainList {
  DEFAULT_SORT = "createdAt"
  EDIT_ROUTE_NAME = "CovidTestResultView"

  @Prop({default: 25})
  pageSize

  @Prop({default: null})
  cols: any
  data: any = {
    singleSelect: false,
    headers: [
      {text: "Name", value: "name"},
      {text: "Mall", value: "site.siteID"},
      {text: "Dept", value: "department.name"},
      {text: "Reason", value: "covidTestReason.name"},
      {text: "Result", value: "testResult"},
      {text: "Close Contact", value: "closeContactWith.name"},
      {text: "Test Date", value: "date"},
      {text: "Created", value: "createdAt"},
    ],
  }

  pageSetting: any = {
    showFilter: false,
    isoDate: false,
    pagination: {
      fields: "name,site.siteID,department.name,createdAt,date,covidTestReason.name,testResult,closeContact,closeContactWith.name",
      sort: "createdAt",
      order: "desc",
      limit: 25,
    },
    filtering: {},
    dateFilter: {},
  }

  filter: any = {}

  created() {
    this.init(covidTestResultService)
  }
  async getListFilter() {
    console.log("getListFilter")
    let filter: any = {}
    filter = Object.assign({}, this.$route.query)
    return filter
  }
  redirectToView(item) {
    let id = item._id
    this.$emit("reloadTicketForId", id)
    this.$router.push({name: "CovidTestResultView", params: {id: id}}).catch(() => {})
  }
  clearFilter(key) {
    let queryVals = Object.assign({}, this.$route.query)
    delete queryVals[key]
    router.replace({name: "CovidTestResult", query: queryVals})
    this.filter = queryVals
    this.getItems()
  }
  filterUpdated(data) {
    console.log("filterUpdated", data)
    let obj = {}
    if (data.key == "range") {
      obj = data.value
    } else {
      obj[data.key] = data.value
    }
    let queryVals = Object.assign({}, this.$route.query, obj)
    router.replace({name: "CovidTestResult", query: queryVals}).catch(() => {})
    this.filter = queryVals
    this.getItems()
  }
}
