import {baseReportingService} from "./base-reporting-service"
import {userService} from "./user-service"

class ReportService {
  URL_PREFIX = "secure/reports/"
  
  async workSchedule(payload: any) {
    if (payload.csv) {
      let string = JSON.stringify(payload)
      let params = "payload=" + encodeURI(string)
      window.location.href = `${baseReportingService.serverApiUrl}${this.URL_PREFIX}submissions?csv=1&token=` + userService.getToken() + "&" + params
      return
    }
    return await baseReportingService.post(`${this.URL_PREFIX}work-schedule`, payload)
  }
  async submissions(payload: any) {
    if (payload.csv) {
      let string = JSON.stringify(payload)
      let params = "payload=" + encodeURI(string)
      window.location.href = `${baseReportingService.serverApiUrl}${this.URL_PREFIX}submissions?csv=1&token=` + userService.getToken() + "&" + params
      return
    }
    return await baseReportingService.post(`${this.URL_PREFIX}submissions`, payload)
  }
}

export const reportService = new ReportService()
