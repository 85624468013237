
import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import {departmentService} from "../../../services/department-service"

@Component({
  components: {},
})
export default class Sites extends Vue {
  dialog = false

  data: any = {
    model: [],
  }

  items: any = []

  async created() {
    let result = await departmentService.getItems({fields: "name,site.siteID"}, {limit: 1000, sort: "name"})
    if (this.$route.query["department"]) {
      let values = this.$route.query["department"]
      this.data.model = Array.isArray(values) ? values : [values]
    }
    Vue.set(this, "items", result.data ? result.data.docs : [])
  }

  confirmed() {
    this.$emit("changed", {key: "department", value: this.data.model})
    this.dialog = false
  }
}
