
import {Component, Vue} from "vue-property-decorator"
import FilterSitesAndDept from "./SitesAndDept.vue"

@Component({
  components: {FilterSitesAndDept},
})
export default class Filters extends Vue {
  dialog = false
  profile

  created() {
    this.profile = this.$store.getters.profile
  }
  filterUpdated(data) {
    this.$emit("changed", data)
  }
  filterMine() {
    console.log("this.profile.username", this.profile)
    this.$emit("changed", {key: "users", value: [this.profile.username]})
  }
}
