import {BaseDomainService} from "./base-domain-service"

class StaffService extends BaseDomainService {
  URL_PREFIX = "staff/"
  CACHE_KEY = "staff-list"

  async loadStaffForSiteAndDepartment(siteId, departmentId) {
    return await this.get(`${this.URL_PREFIX}site/${siteId}/department/${departmentId}`)
  }

   async loadHODsForSiteAndDepartment(siteId, departmentId) {
    return await this.get(`${this.URL_PREFIX}site/${siteId}/department/${departmentId}/hods`)
  }

  async loadStaff(payload) {
    return await this.get(`${this.URL_PREFIX}covid-by-staff-list/all`, payload)
  }
  
}
export const staffService = new StaffService()
