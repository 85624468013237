
import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import {userManagementService} from "../services/user-management-service"
import {utils, rules} from "../shared/utils"
import {userAccessService} from "../services/user-access-service"

@Component({components: {}})
export default class UserAccess extends Vue {
  rules = rules

  @Prop({required: true})
  roles

  @Prop({required: true})
  userId

  @Prop()
  item

  data: any = {
    item: {},
  }
  default: any = {}

  pageSetting: any = {
    bottomSheet: {},
    showDialog: false,
    loading: {
      saving: false,
    },
  }

  form: any = {
    valid: false,
  }

  created() {
    console.log("Team Member User Init")
    Vue
    this.getUser()
  }
  validateSave() {
    if (this.data.item.password != this.data.item.repeatPassword) {
      utils.showSnackbarError("Passport and Repeat Password mismach")
      return false
    }
    return true
  }
  async resetAttempts() {
    let result = await userAccessService.resetAttempts(this.userId)
    if (!result || result.status == "error") {
      utils.showSnackbarError("Unable to reset locked account. " + (result ? result.message : ""))
      return
    }
    utils.showSnackbar("User's account unlocked")
  }
  async save() {
    this.pageSetting.loading.saving = true

    if (!this.form.valid) {
      utils.showSnackbarError("Error! Please complete all required fields and try again")
      this.pageSetting.loading.saving = false
      return
    }
    if (!this.validateSave()) {
      this.pageSetting.loading.saving = false
      return
    }

    let sites = this.item.sites
    console.log("sites", sites)
    if (this.item.sites && this.item.sites.length > 0 && this.item.sites[0].siteID) {
      let siteCodes = this.item.sites.map((i) => {
        return i.siteID
      })
      sites = siteCodes
    }

    let type = this.item ? this.item.type : ""

    const updatedItem = Object.assign({}, this.data.item, {email: this.item.email, phone: this.item.phone, name: this.item.name, sites: sites, staffId: this.userId, type: type, roles: this.roles})

    let result
    if (this.data.item._id) {
      result = await userAccessService.updateUserAccess(this.userId, updatedItem)
    } else {
      updatedItem.id = this.userId //user shares same ID as team member
      result = await userAccessService.saveUserAccess(this.userId, updatedItem)
    }

    if (!result || result.status == "error") {
      utils.showSnackbarError("Unable to save item. " + (result ? result.message : ""))

      this.pageSetting.loading.saving = false
      return
    }

    if (result.user) {
      Vue.set(this.data, "item", result.user)
      utils.showSnackbar("User saved")
      this.pageSetting.showDialog = false
    }

    this.pageSetting.loading.saving = false
  }

  resetBottomSheet() {
    this.pageSetting.bottomSheet.visible = false
    this.pageSetting.bottomSheet.item = null
    this.pageSetting.bottomSheet.text = ""
  }

  async getUser() {
    let user = await userManagementService.getUserAccessUser(this.userId)
    console.log("getUser", user)
    if (user && user.username) {
      Vue.set(this.data, "item", user)
    } else {
      this.data.item = {}
    }
  }
  async showForm() {
    this.pageSetting.showDialog = true
  }
}
