import Vue from "vue"
import VueRouter, {RouteConfig} from "vue-router"
import store from "./../store"

import Home from "../views/Home.vue"
import Summary from "../views/Dashboard/Summary.vue"
import Sites from "../views/Site/Site.vue"
import SiteEdit from "../views/Site/SiteEdit.vue"
import CovidTestReasons from "../views/CovidTestReason/CovidTestReasons.vue"
import CovidTestReasonEdit from "../views/CovidTestReason/CovidTestReasonEdit.vue"
import CloseContactCategory from "../views/CloseContactCategory/CloseContactCategory.vue"
import CloseContactCategoryEdit from "../views/CloseContactCategory/CloseContactCategoryEdit.vue"
import StaffCloseContactReason from "../views/StaffCloseContactReason/StaffCloseContactReason.vue"
import StaffCloseContactReasonEdit from "../views/StaffCloseContactReason/StaffCloseContactReasonEdit.vue"
import CovidTestResult from "../views/CovidTestResult/CovidTestResult.vue"
import CovidTestResultView from "../views/CovidTestResult/CovidTestResultView.vue"
import CovidTestByStaff from "../views/CovidTestResult/CovidTestByStaff.vue"

import Department from "../views/Department/Department.vue"
import DepartmentEdit from "../views/Department/DepartmentEdit.vue"
import Staff from "../views/Staff/Staff.vue"
import StaffEdit from "../views/Staff/StaffEdit.vue"
import StaffView from "../views/Staff/StaffView.vue"
import Login from "../views/User/Login.vue"
import Logout from "../views/User/Logout.vue"
import Settings from "../views/User/Settings.vue"
import ResetPassword from "../views/User/ResetPassword.vue"
import AdminManagement from "../views/User/AdminManagement.vue"
import ManagerManagement from "../views/User/ManagerManagement.vue"
import TestsBySite from "../views/Reports/TestsBySite.vue"
import TestsByDepartment from "../views/Reports/TestsByDepartment.vue"
import TestsByReason from "../views/Reports/TestsByReason.vue"
import TestsByResult from "../views/Reports/TestsByResult.vue"
import WorkScheduleReport from "../views/Reports/WorkSchedule.vue"
import WorkSchedule from "./../views/WorkSchedule/WorkSchedule.vue"

//LazyLoad route
const CovidTest = () => import(/* webpackChunkName: "public" */ "../views/CovidTest/CovidTest.vue")

Vue.use(VueRouter)

let routes: Array<RouteConfig> = [
  {
    path: "/home",
    name: "Home",
    component: Home,
    beforeEnter: (to, from, next) => {
      if (to.name == "Home" && !store.state.token) {
        next("/login")
        return
      }
      next()
    },
  },
  {
    path: "/",
    name: "Summary",
    component: Summary,
    beforeEnter: (to, from, next) => {
      if (to.name == "Summary" && !store.state.token) {
        next("/login")
        return
      }
      next()
    },
  },
  {
    path: "/reports/submissions-by-site",
    name: "TestsBySite",
    component: TestsBySite,
    beforeEnter: (to, from, next) => {
      if (to.name == "TestsBySite" && !store.state.token) {
        next("/login")
        return
      }
      next()
    },
  },
  {
    path: "/reports/submissions-by-department",
    name: "TestsByDepartment",
    component: TestsByDepartment,
    beforeEnter: (to, from, next) => {
      if (to.name == "TestsByDepartment" && !store.state.token) {
        next("/login")
        return
      }
      next()
    },
  },
  {
    path: "/reports/submissions-by-reason",
    name: "TestsByReason",
    component: TestsByReason,
    beforeEnter: (to, from, next) => {
      if (to.name == "TestsByReason" && !store.state.token) {
        next("/login")
        return
      }
      next()
    },
  },

  {
    path: "/reports/work-schedule",
    name: "WorkScheduleReport",
    component: WorkScheduleReport,
    beforeEnter: (to, from, next) => {
      if (to.name == "WorkScheduleReport" && !store.state.token) {
        next("/login")
        return
      }
      next()
    },
  },
  {
    path: "/reports/submissions-by-result",
    name: "TestsByResult",
    component: TestsByResult,
    beforeEnter: (to, from, next) => {
      if (to.name == "TestsByResult" && !store.state.token) {
        next("/login")
        return
      }
      next()
    },
  },

  {
    path: "/work-schedule",
    name: "WorkSchedule",
    component: WorkSchedule,
    beforeEnter: (to, from, next) => {
      if (to.name == "WorkSchedule" && !store.state.token) {
        next("/login")
        return
      }
      next()
    },
  },
  {
    path: "/departments",
    name: "Department",
    component: Department,
    beforeEnter: (to, from, next) => {
      if (to.name == "Department" && !store.state.token) {
        next("/login")
        return
      }
      next()
    },
  },
  {
    path: "/departments/edit/:id",
    name: "DepartmentEdit",
    component: DepartmentEdit,
    beforeEnter: (to, from, next) => {
      if (to.name == "DepartmentEdit" && !store.state.token) {
        next("/login")
        return
      }
      next()
    },
  },
  {
    path: "/staff",
    name: "Staff",
    component: Staff,
    beforeEnter: (to, from, next) => {
      if (to.name == "Staff" && !store.state.token) {
        next("/login")
        return
      }
      next()
    },
  },
  {
    path: "/staff/edit/:id",
    name: "StaffEdit",
    component: StaffEdit,
    beforeEnter: (to, from, next) => {
      if (to.name == "StaffEdit" && !store.state.token) {
        next("/login")
        return
      }
      next()
    },
  },
  {
    path: "/staff/view/:id",
    name: "StaffView",
    component: StaffView,
    beforeEnter: (to, from, next) => {
      if (to.name == "StaffView" && !store.state.token) {
        next("/login")
        return
      }
      next()
    },
  },

  {
    path: "/staff-close-contact-reason",
    name: "StaffCloseContactReason",
    component: StaffCloseContactReason,
    beforeEnter: (to, from, next) => {
      if (to.name == "StaffCloseContactReason" && !store.state.token) {
        next("/login")
        return
      }
      next()
    },
  },
  {
    path: "/staff-close-contact-reason/edit/:id",
    name: "StaffCloseContactReasonEdit",
    component: StaffCloseContactReasonEdit,
    beforeEnter: (to, from, next) => {
      if (to.name == "StaffCloseContactReasonEdit" && !store.state.token) {
        next("/login")
        return
      }
      next()
    },
  },
  {
    path: "/close-contact-category",
    name: "CloseContactCategory",
    component: CloseContactCategory,
    beforeEnter: (to, from, next) => {
      if (to.name == "CloseContactCategory" && !store.state.token) {
        next("/login")
        return
      }
      next()
    },
  },
  {
    path: "/close-contact-category/edit/:id",
    name: "CloseContactCategoryEdit",
    component: CloseContactCategoryEdit,
    beforeEnter: (to, from, next) => {
      if (to.name == "CloseContactCategoryEdit" && !store.state.token) {
        next("/login")
        return
      }
      next()
    },
  },
  {
    path: "/covid-test-results",
    name: "CovidTestResult",
    component: CovidTestResult,
    beforeEnter: (to, from, next) => {
      if (to.name == "CovidTestResult" && !store.state.token) {
        next("/login")
        return
      }
      next()
    },
  },
  {
    path: "/covid-test-results/view/:id",
    name: "CovidTestResultView",
    component: CovidTestResultView,
    beforeEnter: (to, from, next) => {
      if (to.name == "CovidTestResultView" && !store.state.token) {
        next("/login")
        return
      }
      next()
    },
  },
  {
    path: "/covid-test-by-staff",
    name: "CovidTestByStaff",
    component: CovidTestByStaff,
    beforeEnter: (to, from, next) => {
      if (to.name == "CovidTestByStaff" && !store.state.token) {
        next("/login")
        return
      }
      next()
    },
  },

  {
    path: "/covid-test-reason",
    name: "CovidTestReasons",
    component: CovidTestReasons,
    beforeEnter: (to, from, next) => {
      if (to.name == "CovidTestReasons" && !store.state.token) {
        next("/login")
        return
      }
      next()
    },
  },
  {
    path: "/covid-test-reason/edit/:id",
    name: "CovidTestReasonEdit",
    component: CovidTestReasonEdit,
    beforeEnter: (to, from, next) => {
      if (to.name == "CovidTestReasonEdit" && !store.state.token) {
        next("/login")
        return
      }
      next()
    },
  },
  {
    path: "/sites",
    name: "Sites",
    component: Sites,
    beforeEnter: (to, from, next) => {
      if (to.name == "Sites" && !store.state.token) {
        next("/login")
        return
      }
      next()
    },
  },
  {
    path: "/sites/edit/:id",
    name: "SiteEdit",
    component: SiteEdit,
    beforeEnter: (to, from, next) => {
      if (to.name == "SiteEdit" && !store.state.token) {
        next("/login")
        return
      }
      next()
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    beforeEnter: (to, from, next) => {
      if (to.name !== "settings" && !store.state.token) next({name: "/login"})
      else next()
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {layout: "simple"},
    component: Logout,
    beforeEnter: (to, from, next) => {
      if (to.name == "logout" && !store.state.token) {
        next("/login")
        return
      }
      next()
    },
  },
  {
    path: "/login",
    name: "login",
    meta: {layout: "simple"},
    component: Login,
  },

  {
    path: "/covid-test/:id",
    name: "CovidTestID",
    meta: {layout: "public"},
    component: CovidTest,
  },
  {
    path: "/covid-test",
    name: "CovidTest",
    meta: {layout: "public"},
    component: CovidTest,
  },
  {
    path: "/reset-password/:key",
    name: "resetPassword",
    meta: {layout: "simple"},
    component: ResetPassword,
  },
  {
    path: "/user-management/manager",
    name: "ManagerManagement",
    component: ManagerManagement,
    beforeEnter: (to, from, next) => {
      if (to.name == "ManagerManagement" && !store.state.token) {
        next("/login")
        return
      }
      next()
    },
  },
  {
    path: "/user-management/admin",
    name: "userManagementAdmin",
    component: AdminManagement,
    beforeEnter: (to, from, next) => {
      if (to.name == "userManagementAdmin" && !store.state.token) {
        next("/login")
        return
      }
      next()
    },
  },
  {path: "*", redirect: "/"},
].map((i: any) => {
  if(!i.meta){
    i['meta'] = {layout: "app"} //det default layout
  }
  return i
})

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

export default router
