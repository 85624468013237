
import {Component, Vue, Watch} from "vue-property-decorator"
import DataItem from "../../components/DataItem.vue"
import Header from "./Header.vue"
import CloseContact from "./CloseContact.vue"
import History from "./History.vue"
import PositiveSummary from "./PositiveSummary.vue"
import TestResultImage from "./TestResultImage.vue"
import {covidTestResultService} from "./../../services/covid-test-result-service"
import {userService} from "@/services/user-service"
import { utils } from "@/shared/utils"

@Component({
  components: {DataItem, Header, CloseContact, PositiveSummary, TestResultImage, History},
})
export default class CovidTestResultView extends Vue {
  profile

  data: any = {
    itemId: 0,
    ticket: {},
  }

  subnavitems = []

  pageSettings: any = {
    taskForm: false,
    allowDelete: false,
    bottomSheet: {
      visible: false,
    },
  }
  ticketViewInfo = {out: {}, in: []}

  created() {
    this.data.itemId = String(this.$route.params.id)
    this.init()
    this.profile = this.$store.getters.profile
    if (userService.hasRole("ADMIN")) {
      this.pageSettings.allowDelete = true
    } else {
      this.pageSettings.allowDelete = false
    }
  }
  async init() {
    console.log("loading init data")
    let result = await covidTestResultService.loadView(this.data.itemId)
    if (!result.error) {
      Vue.set(this, "data", result.data)
    }
  }
  backButtonClicked() {
    this.$router.back()
  }
  reloadForId(id) {
    this.data.itemId = String(id)
    this.init()
  }
  view(id) {
    this.$router.push({name: "StaffView", params: {id: id}}).catch(() => {})
    this.$emit("reloadForId", id)
  }
  async deleteItem() {
    let result = await covidTestResultService.deleteItem(this.data._id)
    this.pageSettings.bottomSheet.visible = false
    if (!result || result.error) {
      utils.showSnackbarError("Unable to delete item. " + (result ? result.errorMessage : ""))
      return
    }

    this.$router.push({name: "CovidTestResult"})
    utils.showSnackbarSuccess(`Item deleted successfully`)
  }
}
