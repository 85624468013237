import {baseService} from "./base-service"

export class BaseDomainService {
  URL_PREFIX = ""
  
  validate(){
    if(this.URL_PREFIX==""){
      console.log("Error. Missing URL PREFIX")
      return false
    }
    return true
  }

  async find(filter: any) {
    if(!this.validate()){
      return false  
    }
    return await this.get(`${this.URL_PREFIX}find`, filter); 
  }

  async getItems(payload: any, pagination: any) {
    if(!this.validate()){
      return false
    }
    return await this.get(`${this.URL_PREFIX}`, payload, pagination);
  }
  async deleteItem(itemId: string) {
    if(!this.validate()){
      return false  
    }
    return await this.delete(`${this.URL_PREFIX}${itemId}`,{});
  }

  async getItem(itemId: string) {
    if(!this.validate()){
      return false  
    }
    return await this.get(`${this.URL_PREFIX}${itemId}`, {});
  }

  async updateItem(payload: any) {
    if(!this.validate()){
      return false  
    }
    return await this.post(`${this.URL_PREFIX}${payload._id}`, payload);
  }

  async createItem(payload: any) {
    if(!this.validate()){
      return false  
    }
    return await this.post(`${this.URL_PREFIX}`, payload);
  }

  async delete(urlSuffix: string, payload: any) {
    return await baseService.delete(urlSuffix, payload)
  }
  async post(urlSuffix: string, payload: any) {
    return await baseService.post(urlSuffix, payload)
  }

  async get(urlSuffix: string, payload: any = {}, pagination: any = {}) {
    return await baseService.get(urlSuffix, payload, pagination)
  }

  async put(urlSuffix: string, payload: any) {
    return await baseService.put(urlSuffix, payload)
  }

}