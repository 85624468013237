import axios from "axios"
import router from "./../router"
import eventBus from "./../eventBus"
import store from "./../store"
import {ANALYTICS_URL, utils} from "./../shared/utils"
import {userService} from "./user-service"

class BaseReportingService {
  serverApiUrl = ANALYTICS_URL

  async delete(urlSuffix: string, payload: any) {
    try {
      let result = await axios.delete(this.getUrl(urlSuffix), Object.assign({}, {params: payload}, this.loadHeaders()))
      return result.data
    } catch (error) {
      if (error instanceof Error) {
        console.error(error)
        if (error && error.message && error.message.indexOf("403") >= 0) {
          eventBus.$emit("display-snackbar", {type: "error", text: "Access denied. Please login with correct credentials to access the system"})
          this.redirectToLogin()
        }
      }
      return null
    }
  }
  async post(urlSuffix: string, payload: any) {
    try {
      let result = await axios.post(this.getUrl(urlSuffix), payload, this.loadHeaders())
      return result.data
    } catch (error) {
      if (error instanceof Error) {
      console.error(error)
      if (error && error.message && error.message.indexOf("403") >= 0) {
        eventBus.$emit("display-snackbar", {type: "error", text: "Access denied. Please login with correct credentials to access the system"})
        this.redirectToLogin()
      }
      }
      return null
    }
  }

  async get(urlSuffix: string, payload: any = {}, pagination: any = {}) {
    try {
      let newpay = Object.assign({}, payload, pagination)
      let options = Object.assign({}, {params: newpay}, this.loadHeaders())
      let result = await axios.get(this.getUrl(urlSuffix), options)
      return result.data
    } catch (error) {
      if (error instanceof Error) {
      console.error(error)
      if (error && error.message && error.message.indexOf("403") >= 0) {
        eventBus.$emit("display-snackbar", {type: "error", text: "Access denied. Please login with correct credentials to access the system"})
        this.redirectToLogin()
      }
      }
      return null
    }
  }

  async put(urlSuffix: string, payload: any) {
    try {
      let result = await axios.put(this.getUrl(urlSuffix), payload, this.loadHeaders())
      return result.data
    } catch (error) {
      if (error instanceof Error) {
      console.error(error)
      if (error && error.message && error.message.indexOf("403") >= 0) {
        eventBus.$emit("display-snackbar", {type: "error", text: "Access denied. Please login with correct credentials to access the system"})
        this.redirectToLogin()
      }
      }
      return null
    }
  }

  async postUpload(urlSuffix: string, payload: any, options: any) {
    try {
      console.log("this.loadHeaders()", this.loadHeaders())

      let optionsAppended = Object.assign({}, this.loadHeaders(), options)
      optionsAppended["headers"]["Content-Type"] = "multipart/form-data"
      console.log("optionsAppended", optionsAppended)
      let result = await axios.post(this.getUrl(urlSuffix), payload, optionsAppended)
      return result.data
    } catch (error) {
      if (error instanceof Error) {
      console.error(error)
      if (error && error.message && error.message.indexOf("403") >= 0) {
        eventBus.$emit("display-snackbar", {type: "error", text: "Access denied. Please login with correct credentials to access the system"})
        this.redirectToLogin()
      }
      }
      return null
    }
  }

  loadHeaders() {
    let headers: any = {authorization: "Bearer " + userService.getToken()}
    let policyToken = userService.getPolicyToken()
    if (policyToken) {
      headers.policy = policyToken
    }
    return {headers: headers}
  }

  redirectToLogin() {
    router.push("login").catch((error) => utils.supressNavigationDuplicatedError(error))
  }

  getUrl(urlSuffix: string) {
    return `${this.serverApiUrl}${urlSuffix}`
  }
}

export const baseReportingService = new BaseReportingService()
