
import {Component, Vue, Watch} from "vue-property-decorator"
import DataItem from "../../components/DataItem.vue"
import {staffService} from "../../services/staff-service"
import Header from "./Header.vue"
import History from "./History.vue"
import LastResult from "./LastResult.vue"
import TestResultImage from "./TestResultImage.vue"
import HODs from "./HODs.vue"
@Component({
  components: {DataItem, Header, History, LastResult, TestResultImage, HODs},
})
export default class CovidTestResultView extends Vue {
  profile

  data: any = {
    itemId: 0,
    ticket: {},
  }

  subnavitems = []

  pageSettings: any = {
    taskForm: false,
  }
  ticketViewInfo = {out: {}, in: []}

  created() {
    this.data.itemId = String(this.$route.params.id)
    this.init()
    this.profile = this.$store.getters.profile
  }
  async init() {
    console.log("loading init data")
    let result = await staffService.getItem(this.data.itemId)
    if (!result.error) {
      Vue.set(this, "data", result.data)
    }
  }
  backButtonClicked() {
    this.$router.back()
  }
  reloadForId(id) {
    this.data.itemId = String(id)
    this.init()
  }
  reloadForStaffId(id) {
    this.$router.push({name: "StaffView", params: {id: id}}).catch(() => {})
    console.log("reloadForStaffId", id)
    this.data.itemId = String(id)
    this.init()
  }
  edit() {
    this.$router.push({name: "StaffEdit", params: {id: this.data._id}}).catch(() => {})
  }
}
