
import {Component, Prop, Vue} from "vue-property-decorator"
@Component({
  components: {},
})
export default class DataItem extends Vue {
  @Prop()
  customClasses

  @Prop({})
  value

  @Prop({required: true})
  label

  @Prop({default: false})
  newLine

  @Prop({})
  date

  @Prop({})
  since

  @Prop({})
  defaultValue

  @Prop({})
  withSince

  @Prop({})
  max
}
