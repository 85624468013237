
import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import {siteService} from "./../../../services/site-service"
import {cacheSrv} from "./../../../services/cache"
import eventBus from "../../../eventBus"
import moment from "moment"
import {dateFilterCommonConfig} from "./../../../shared/utils"

@Component({
  components: {},
})
export default class Sites extends Vue {
  CACHE_KEY = "range"
  datePeriodOptions = dateFilterCommonConfig.quick

  data: any = {
    startDatePicker: false,
    endDatePicker: false,
    filter: {
      startDate: moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    },
  }

  async created() {
    let range = cacheSrv.cached(this.CACHE_KEY)
    if (!range) {
      cacheSrv.cacheEx(this.CACHE_KEY, this.data.filter, 600)
      this.$store.dispatch("cacheRangeSet", this.data.filter)
      this.confirmed()
    } else {
      this.data.filter.startDate = moment(range.startDate).format("YYYY-MM-DD")
      this.data.filter.endDate = moment(range.endDate).format("YYYY-MM-DD")
      this.confirmed()
    }
    eventBus.$on("cacheFilterClear", this.cacheFilterClearEvent)
  }
  beforeDestroy() {
    eventBus.$off("cacheFilterClear", this.cacheFilterClearEvent)
  }
  applyDateForOption(key, value) {
    this.data.filter.startDate = moment(value[0]).format("YYYY-MM-DD")
    this.data.filter.endDate = moment(value[1]).format("YYYY-MM-DD")
    cacheSrv.cacheEx(this.CACHE_KEY, this.data.filter, 30)
    this.$store.dispatch("cacheRangeSet", this.data.filter)
    this.confirmed()
    
    // this.filter.dateFilter = key;
    // let selectedDateRanged = value.toString();
    // let dateRange = selectedDateRanged.split(",");
    // this.filter.fromDate = moment(dateRange[0]).toDate();
    // this.filter.toDate = moment(dateRange[1]).toDate();
    // this.emitFilter();
  }
  // momentifyDatePickerFormat(dates) {
  //   dates.startDate = moment(dates.startDate).toISOString()
  //   dates.endDate = moment(dates.endDate).toISOString()
  // }
  cacheFilterClearEvent(payload) {
    if (payload && payload.key == "range") {
      Vue.set(this.data, "filter", {startDate: null, endDate: null})
      cacheSrv.cache(this.CACHE_KEY + "-last", this.data.filter)
      this.$store.dispatch("cacheRangeSet", this.data.filter)
      this.$emit("changed", {key: "range", value: this.data.filter})
    }
  }

  @Watch("data.filter.endDate")
  updatedED(date) {
    console.log("endDate changed", date)
    this.data.filter.endDate = date
    cacheSrv.cacheEx(this.CACHE_KEY, this.data.filter, 30)
    this.$store.dispatch("cacheRangeSet", this.data.filter)
    this.confirmed()
  }
  @Watch("data.filter.startDate")
  updatedSD(date) {
    console.log("startDate changed", date)
    this.data.filter.startDate = date
    cacheSrv.cacheEx(this.CACHE_KEY, this.data.filter, 30)
    this.$store.dispatch("cacheRangeSet", this.data.filter)
    this.confirmed()
  }

  confirmed() {
    // cacheSrv.cache(this.CACHE_KEY + "-last", this.data.filter)
    // this.$store.dispatch("cacheRangeSet", this.data.filter)
    this.$emit("changed", {key: "range", value: this.data.filter})
  }
}
