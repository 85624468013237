
import {Component, Vue} from "vue-property-decorator"
import store from "./../../store"
import {userService} from "./../../services/user-service"
import {preferenceService} from "./../../services/preferences-service"
import {utils} from "./../../shared/utils"
import TipTap from "./../../components/TipTap.vue"

@Component({
  components: {TipTap},
})
export default class Home extends Vue {
  data: any = {
    step: "settings",
    rules: {
      //emailMatch: () => ((this.data.user.password!=this.data.user.confirmPassword)?'The email and password you entered don\'t match',''),
    },
    profile: {},
    edit: false,
    user: {
      password: "",
      confirmPassword: "",
    },
    preference: {
      emailSignature:""
    },
    item: {
      email: "",
      phone: "",
      name: "",
    },
  }

  constructor() {
    super()
  }

  created() {
    this.init()
    this.load()
  }

  edit() {
    this.data.edit = !this.data.edit
  }

  async load() {
    let result = await userService.loadProfile()
    if (result && result.error == true) {
      utils.showSnackbar("Error loading profile")
      return
    }
    if (result) {
      this.data.item = Object.assign({}, result)
    }

    let pref = await preferenceService.getPreference()
    if(pref){
      Vue.set(this.data.preference,"emailSignature",pref.emailSignature)
    }
  }
  async changePassword() {
    this.data.step = "change-password"
  }

  async saveEmailSignature(){
    let result = await preferenceService.saveEmailSignature(this.data.preference)
    if (result == true) {
      utils.showSnackbarError("Failed to update email signature")
    }
    else{
      utils.showSnackbarSuccess("Updated email signature")
    }
  }

  async saveNewPassword() {
    if (!this.data.user.password) {
      //EventBus.$emit("display-snackbar", {type: "error", text: "Please enter password and confirm password"})
      utils.showSnackbar("Please enter password and confirm password")
      return
    }
    if (this.data.user.password != this.data.user.confirmPassword) {
      //EventBus.$emit("display-snackbar", {type: "error", text: "Password and confirm password do not match"})
      utils.showSnackbar("Password and confirm password do not match")
      return
    }
    let result = await userService.saveNewPassword(this.data.user)
    if (result == true) {
      //EventBus.$emit("display-snackbar", {type: "info", text: "Password updated successfully"})
      utils.showSnackbar("Password updated successfully")
      //this.data.step = "settings"
    }

    console.log("result from saving new pass", result)
  }

  async save() {
    if (!this.data.item.email) {
      //EventBus.$emit("display-snackbar", {type: "error", text: "Email is required"})
      utils.showSnackbar("Email is required")
      return
    }
    let result = await userService.saveProfile(this.data.item)
    if (result && result.error == true) {
      //EventBus.$emit("display-snackbar", {type: "error", text: (result.message?result.message:"Error saving profile"), subtext: result.message})
      utils.showSnackbar("Error saving profile")
      return
    }
    if (result) {
      this.data.item = Object.assign({}, result)
      this.data.edit = false
    }
  }

  async init() {
    this.data.profile = store.getters.profile
  }
}
