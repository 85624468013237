import Vue from "vue"
import Vuex from "vuex"
import {cacheSrv} from "./../services/cache"
import moment from "moment"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lastLink: cacheSrv.cached("last-link")||0,
    leftMenuItems: [],
    token: "",
    profile: {},
    cacheFilters:{
      stages: cacheSrv.cached("stage-list-last")||["NEW","ACTIVE"],
      sites: cacheSrv.cached("site-list-last")||[],
      topics: cacheSrv.cached("topics-list-last")||[],
      sources: cacheSrv.cached("ticket-source-list-last")||[],
      users: cacheSrv.cached("users-list-last")||[],
      csUsers: cacheSrv.cached("cs-users-list-last")||[],
      teams: cacheSrv.cached("team-list-last")||[],
      categories: cacheSrv.cached("categories-list-last")||[],
      range: cacheSrv.cached("range")?cacheSrv.cached("range"):{startDate: moment().add(-30,'days').format("YYYY-MM-DD"), endDate: moment().endOf('day').format("YYYY-MM-DD")},
      actionStatus: cacheSrv.cached("action-status-list-last")||[],
      
    }
  },
  mutations: {
    updateLastLink(state, linkIndex) {
      state.lastLink = linkIndex
      cacheSrv.cache("last-link", linkIndex)
    },
    updateLeftMenu(state, items) {
      state.leftMenuItems = items
    },
    updateProfile(state, profile) {
      state.profile = profile
    },
    updateToken(state,token){
      state.token = token
    },
    cacheStageSet(state, val) {
      state.cacheFilters.stages = val
    },
    cacheSiteSet(state, val) {
      state.cacheFilters.sites = val
    },
    cacheSourceSet(state, val) {
      state.cacheFilters.sources = val
    },
    cacheTopicSet(state, val) {
      state.cacheFilters.topics = val
    },
    cacheUserSet(state, val) {
      state.cacheFilters.users = val
    },
    cacheTeamSet(state, val) {
      state.cacheFilters.teams = val
    },
    cacheCSUserSet(state, val) {
      state.cacheFilters.csUsers = val
    },
    cacheCategorySet(state, val) {
      state.cacheFilters.categories = val
    },
    cacheRangeSet(state, val) {
      state.cacheFilters.range = val
    },
    cacheActionStatusSet(state, val) {
      state.cacheFilters.actionStatus = val
    }
  },
  getters: {
    profile: (state) => {
      let profile = state.profile
      return profile
    },
    lastLink: (state) => {
      return state.lastLink
    },
    token: state=>{
      return state.token
    }
  },
  actions: {
    updateLeftMenu(context, val) {
      context.commit('updateLeftMenu', val);
    },
    updateLastLink(context, val) {
      context.commit('updateLastLink', val);
    },
    cacheStageSet(context, val) {
      context.commit('cacheStageSet', val);
    },
    cacheSiteSet(context, val) {
      context.commit('cacheSiteSet', val);
    },
    cacheSourceSet(context, val) {
      context.commit('cacheSourceSet', val);
    },
    cacheTopicSet(context, val) {
      context.commit('cacheTopicSet', val);
    },
    cacheUserSet(context, val) {
      context.commit('cacheUserSet', val);
    },
    cacheTeamSet(context, val) {
      context.commit('cacheTeamSet', val);
    },
    cacheCSUserSet(context, val) {
      context.commit('cacheCSUserSet', val);
    },
    cacheCategorySet(context, val) {
      context.commit('cacheCategorySet', val);
    },
    cacheRangeSet(context, val) {
      context.commit('cacheRangeSet', val);
    },
    cacheActionStatusSet(context, val) {
      context.commit('cacheActionStatusSet', val);
    }
  },
  modules: {},
})
