
import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import eventBus from "../../eventBus"
import moment from "moment"
import {dashboardService} from "../../services/dashboard-service"

@Component({
  components: {},
})
export default class CasesByReason extends Vue {
  @Prop({required: true})
  items

  filters: any = {
    data: {},
  }
  data: any = {
    options: {
      theme: {
        palette: "palette6",
      },
      grid: {
        show: false,
      },
      chart: {
        type: "bar",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 5,
          left: 2,
          blur: 5,
          opacity: 0.2,
        },
        id: "tickets-by-source",
        height: "250",
        events: {
          click: (event, chartContext, config) => {
            // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
            console.log("Clicked", config)
            this.goto(config)
          },
        },
      },
      dataLabels: {
        enabled: true,
      },
      legend: {
        show: false,
      },
      labels: {
        style: {
          fontSize: "10px",
        },
      },
      plotOptions: {
        bar: {
          distributed: true,
        },
      },
      xaxis: {
        categories: [],
      },
    },
    series: [
      {
        name: "Tickets Count",
        data: [],
      },
    ],
  }

  created() {
    this.loadChart()
  }

  @Watch("items")
  refresh(val) {
    this.loadChart()
  }

  async loadChart() {
    let labels = this.items.map((i) => i._id.reason)
    let values = this.items.map((i) => i.count)
    this.data.options = Object.assign({}, this.data.options, {xaxis: {categories: labels}}) // chart: {height: String(50*labels.length)}
    this.data.series = [{data: values}]
  }
  goto(config) {
    if (config && config.dataPointIndex >= 0) {
      let selectedValue = this.data.options.xaxis.categories[config.dataPointIndex]
      let query: any = {source: selectedValue}
      this.$router.push({name: "Tickets", query: query})
    }
  }
}
