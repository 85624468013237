import axios from "axios"
import router from "./../router"
import {userService} from "./user-service"
import {utils} from "@/shared/utils"
import eventBus from "@/eventBus"

class UserManagementService {
  ssoApiUrl = process.env.VUE_APP_HR_SSO_API
  ADMIN_URL_SEGMENT = "/api/super-admin/"
  MANAGER_URL_SEGMENT = "/api/admin/manager/"
  USER_ACCESS_URL_SEGMENT = "/api/admin/user-access-management/"
  USERS_CACHE_KEY = "user-list"

  async getManager(pagination: any) {
    return await this.get(`${this.MANAGER_URL_SEGMENT}users`, pagination)
  }
  async createManager(payload: any) {
    return await this.post(`${this.MANAGER_URL_SEGMENT}register`, payload)
  }

  async updateManager(payload: any) {
    return await this.post(`${this.MANAGER_URL_SEGMENT}users/${payload.userId}`, payload)
  }

  async deleteManager(payload: any) {
    return await this.post(`${this.MANAGER_URL_SEGMENT}users/delete/${payload._id}`, payload)
  }
  //SSO related to ADMINs
  async getAdministrators(pagination: any) {
    return await this.get(`${this.ADMIN_URL_SEGMENT}users`, pagination)
  }

  async createAdminUser(payload: any) {
    return await this.post(`${this.ADMIN_URL_SEGMENT}register`, payload)
  }

  async updateAdminUser(payload: any) {
    return await this.post(`${this.ADMIN_URL_SEGMENT}users/${payload.userId}`, payload)
  }

  async deleteAdministrator(payload: any) {
    return await this.post(`${this.ADMIN_URL_SEGMENT}users/delete/${payload._id}`, payload)
  }

  async getUserAccessUser(userId: string) {
    console.log("userId", userId)
    return await this.get(`${this.USER_ACCESS_URL_SEGMENT}${userId}`)
  }

  async updateUserAccess(payload: any) {
    return await this.post(`${this.USER_ACCESS_URL_SEGMENT}${payload._id}`, payload)
  }
  async saveUserAccess(payload: any) {
    return await this.post(`${this.USER_ACCESS_URL_SEGMENT}register`, payload)
  }
  async deleteUserAccess(userId: string) {
    return await this.delete(`${this.USER_ACCESS_URL_SEGMENT}${userId}`, {})
  }

  //SSO BASE SERVICE
  getUrl(urlSuffix: string) {
    return `${this.ssoApiUrl}${urlSuffix}`
  }

  async post(urlSuffix: string, payload: any) {
    try {
      let result = await axios.post(this.getUrl(urlSuffix), payload, this.loadHeaders())
      return result.data
    } catch (error) {
      if (error instanceof Error) {
        console.error(error)
        if (error && error.message && error.message.indexOf("403") >= 0) {
          eventBus.$emit("display-snackbar", {type: "error", text: "Access denied. Please login with correct credentials to access the system"})
          this.redirectToLogin()
        }
      }
      return null
    }
  }

  async get(urlSuffix: string, payload: any = {}, pagination: any = {}) {
    try {
      let newpay = Object.assign({}, payload, pagination)
      let options = Object.assign({}, {params: newpay}, this.loadHeaders())
      let result = await axios.get(this.getUrl(urlSuffix), options)
      return result.data
    } catch (error) {
      if (error instanceof Error) {
        console.error(error)
        if (error && error.message && error.message.indexOf("403") >= 0) {
          eventBus.$emit("display-snackbar", {type: "error", text: "Access denied. Please login with correct credentials to access the system"})
          this.redirectToLogin()
        }
      }
      return null
    }
  }
  async delete(urlSuffix: string, payload: any) {
    try {
      let result = await axios.delete(this.getUrl(urlSuffix), Object.assign({}, {params: payload}, this.loadHeaders()))
      return result.data
    } catch (error) {
      if (error instanceof Error) {
        console.error(error)
        if (error && error.message && error.message.indexOf("403") >= 0) {
          eventBus.$emit("display-snackbar", {type: "error", text: "Access denied. Please login with correct credentials to access the system"})
          this.redirectToLogin()
        }
      }
      return null
    }
  }

  loadHeaders() {
    let headers: any = {authorization: "Bearer " + userService.getToken()}
    let policyToken = userService.getPolicyToken()
    if (policyToken) {
      headers.policy = policyToken
    }
    return {headers: headers}
  }

  redirectToLogin() {
    router.push("login").catch((error) => utils.supressNavigationDuplicatedError(error))
  }
}

export const userManagementService = new UserManagementService()
